import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
  TextField,
  Snackbar,
  Switch,
  CircularProgress,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuList,
  Tabs,
  Tab,
  NativeSelect,
  InputBase,
  ClickAwayListener,
} from "@material-ui/core";
import {
  ArrowBackOutlined,
  Edit,
  ExpandMore,
  ArrowDropDown,
  Close,
} from "@material-ui/icons";
import Clear from "@material-ui/icons/Clear";
import { Autocomplete, Alert } from "@material-ui/lab";
import SyncIcon from "@material-ui/icons/Sync";
import axios from "axios";
import {
  URL,
  ADMIN_KEY,
  APP_URL,
  CORE_API_URL,
  CAMPAIGN_URL,
  INTEGRATION_SERVER_URL,
  CHARGEBEE_REDIRECT_URL,
  partnerURL,
  PARTNER_APP_URL,
  TENANT_ID,
  URL as CLIENT_URL,
} from "../../config/config";
import { validLimits } from "../../config/rateLimits";
import moment from "moment";
import PlanFamily from "../../commons/PlanFamily/PlanFamily";
import { isEmpty } from "lodash";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ReactJson from "react-json-view";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { DateRangePicker } from "react-dates";
import ApplyBvbp from "./ApplyBvbp.js";

const options = [
  "Owner Login",
  "WhiteLabel Login",
  "Partner Dashboard Login",
  "Copy Login URL",
];

const Webhook_Topics = [
  { id: 1, label: "contact.created" },
  { id: 2, label: "contact.attribute.updated" },
  { id: 3, label: "message.created" },
  { id: 4, label: "message.status.updated" },
  { id: 5, label: "contact.tag.added" },
  { id: 6, label: "contact.tag.removed" },
  { id: 7, label: "contact.first_message.added" },
  { id: 8, label: "contact.first_message.removed" },
  { id: 9, label: "contact.chat.intervened" },
  { id: 10, label: "contact.chat.closed" },
  { id: 11, label: "contact.chat.requesting" },
  { id: 12, label: "contact.campaign.sent" },
  { id: 13, label: "contact.campaign.delivered" },
  { id: 14, label: "contact.campaign.read" },
  { id: 15, label: "message.sender.user" },
  { id: 16, label: "payment.captured" },
  { id: 17, label: "payment.refunded" },
  { id: 18, label: "order.placed" },
  { id: 19, label: "message_status" },
  { id: 20, label: "incoming_message" },
  { id: 21, label: "message_template_status_update" },
  { id: 22, label: "phone_number_quality_update" },
  { id: 23, label: "phone_number_name_update" },
  { id: 24, label: "account_alerts" },
  { id: 25, label: "account_review_update" },
  { id: 26, label: "account_update" },
  { id: 27, label: "business_capability_update" },
  { id: 28, label: "message_template_quality_update" },
  { id: 29, label: "security" },
  { id: 30, label: "template_category_update" },
  { id: 31, label: "campaign_status_update" },
  { id: 32, label: "business_status_update" },
  { id: 33, label: "message.clicked" },
  { id: 34, label: "message.replied" },
];

const PLANS = [
  "BASIC_TRIAL",
  "BASIC_MONTHLY",
  "BASIC_DIWALI_MONTHLY",
  "PRO_MONTHLY",
  "ENTERPRISE_MONTHLY",
  "BASIC_YEARLY",
  "BASIC_DIWALI_YEARLY",
  "PRO_YEARLY",
  "ENTERPRISE_YEARLY",
  "FREE",
  "LITE",
  "PRO",
  "PLUS",
  "PREMIUM",
  "BASIC_MONTHLY_TIER_1",
  "BASIC_MONTHLY_TIER_2",
  "BASIC_MONTHLY_TIER_3",
  "BASIC_MONTHLY_TIER_4",
  "BASIC_MONTHLY_TIER_5",
  "BASIC_MONTHLY_TIER_6",
  "BASIC_MONTHLY_TIER_7",
  "BASIC_MONTHLY_TIER_8",
  "BASIC_YEARLY_TIER_1",
  "BASIC_YEARLY_TIER_2",
];

const PROJECT_STATUS = [
  "active",
  "unpaid",
  "terminated",
  "pending",
  "suspended",
  "stopped",
  "archived",
];

const Tier_Limit = [
  { name: "Tier -2 : 2", value: 2 },
  { name: "Tier -1 : 50", value: 50 },
  { name: "Tier 0 : 250", value: 250 },
  { name: "Tier 1 : 1000", value: 1000 },
  { name: "Tier 2 : 10,000", value: 10000 },
  { name: "Tier 3 : 1,00,000", value: 100000 },
  { name: "Tier 4 : 10,00,000", value: 1000000 },
];

const WHATSAPP_PAY_TYPES = [
  { name: "Native Whatsapp Gateway", value: "native_whatsapp_gateway" },
  {
    name: "Native Whatsapp Gateway , Payment Link",
    value: "native_whatsapp_gateway+payment_link",
  },
  { name: "Payment Link", value: "payment_link" },
  { name: "Native Whatsapp UPI", value: "native_whatsapp_upi" },
  {
    name: "Native Whatsapp UPI , Payment Link",
    value: "native_whatsapp_upi+payment_link",
  },
  {
    name: "Disabled",
    value: "",
  },
];

const TEMPLATE_ENABLE_BY_META_TYPES = [
  { name: "Completed", value: "COMPLETED" },
  { name: "Approved", value: "APPROVED" },
  {
    name: "Requested",
    value: "REQUESTED",
  },
  {
    name: "Disabled",
    value: "",
  },
];

const WABATPT_TIERS_THROUGHTPUT = {
  TIER_1: 40,
  TIER_2: 80,
  TIER_3: 150,
  TIER_4: 250,
  TIER_5: 500,
  TIER_6: 1000,
};

const WABATPT_TIERS = Object.keys(WABATPT_TIERS_THROUGHTPUT);

// function getStatusColor() {
//   const templateStatus = "PENDING";
//   switch (templateStatus) {
//     case "PENDING":
//       return "grey";
//     case "APPROVED":
//       return "#08CF65";
//     case "REJECTED":
//       return "#ff0000";
//     default:
//       return "pink";
//   }
// }
// const ADD_ONS = [
//   {
//     name: "Single Sign On",
//     key: "SSO",
//   },
//   {
//     name: "Webhooks",
//     key: "WEBHOOKS",
//   },
//   {
//     name: "Shopify Integration",
//     key: "SHOPIFY",
//   },
//   {
//     name: "Woocommerce Integration",
//     key: "WOOCOMMERCE",
//   },
//   {
//     name: "Meta Ads",
//     key: "META_ADS",
//   },
//   {
//     name: "Flow Builder",
//     key: "FLOW_BUILDER",
//   },
// ];

const PARTNER_PLANS = [
  "BASIC_MONTHLY",
  "PRO_MONTHLY",
  "ENTERPRISE_MONTHLY",
  "BASIC_YEARLY",
  "PRO_YEARLY",
  "ENTERPRISE_YEARLY",
];

class Assistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wooCommerceLogs: { WooComOrderEvents: [], WooComAbandonedEvents: [] },
      open: false,
      selectedIndex: 0,
      expandedWooCommerceLogIndex: null,
      selectedWooCommerceTab: 0,
      shopifyLogs: [],
      expandedLogIndex: null,
      creditLineInfo: "",
      wabaInfo: null,
      wabaInfoLoading: true,
      fbmInfoLoading: true,
      FBMname: "",
      showCode: false,
      rejectionReasons: [],
      verificationStatus: "",
      bvbpApplicationResponse: [],
      status: "PENDING",
      bvbpDialog: false,
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      remainingCredit: 0,
      activePlan: this.props.template,
      whatsappNumber: "",
      appName: "",
      templateCredit: 0,
      apiKey360: "",
      cachedAssistant: null,
      cachedPartner: null,
      projectStatus: "",
      mode: "SMS",
      pin: "",
      verifyCode: "",
      password: "",
      about: "",
      messageLimit: "",
      minAllowedBalance: 0,
      qualityRating: "",
      agentLimit: 0,
      attributeLimit: 0,
      tagLimit: 0,
      providerType: "",
      fbManager: "",
      ownerName: "",
      testNumber: 0,
      displayNameVerified: "",
      assistantApiLimit: 0,
      businessId:
        this.props.templates[this.props.templateIndex]?.fbBusinessId || "",
      bvbpCount: "",
      isChatScriptingRunning: false,
      directApiWebookUrl:
        this.props.templates[this.props.templateIndex].directApiWebookUrl || "",
      directApikey:
        this.props.templates[this.props.templateIndex].directApikey || "",
      flowConfiguration: this.props.templates[this.props.templateIndex]
        .flowConfiguration || {
        isEnabled: false,
        flowCount: 0,
        activeFlowCount: 0,
        isTrialClaimed: true,
        removedOn: null,
        addedOn: Date.now(),
        changesScheduledAt: null,
      },
      // verifiedStatus: null,
      webhooks: false,
      wabaTpt:
        this.props.templates[this.props.templateIndex].wabaTpt || "TIER_1",
      wabaStatusTpt:
        this.props.templates[this.props.templateIndex].wabaStatusTpt ||
        "TIER_1",
      migrationNumber: null,
      srcAssistantId: null,
      partnerId: null,
      defaultPlan: "BASIC_MONTHLY",
      activationDate: null,
      migrateTemplatesTo: null,
      migrateTemplatesFrom: null,
      whatsappPaySlabs: "{}",
      editWhatsappPaySlabs: false,
      directApiCredentials: {
        username: null,
        passoword: null,
        refreshToken: null,
        accessToken: null,
      },
      privateApps: [], // [ privateAppId: "", webhooks: [{ topicsArray: [], webhookUrl: "", webhookId: "" }], apiPassword:"" ]
      isLoading: true,
      isDialogOpen: false,
      open: false,
      currentIndex: null,
      isDrawerOpen: false,
      isEcomDrawerOpen: false,
      logs: [],
      wabaProgressStep: 0,
      scheduleCampaignLimit: 0,
      wabaAppId: "",
      wabaNumberId: "",
      billingProcessDialog: false,
      paymentRemarks: "",
      openWebhookDialog: false,
      webhookLogs: [],
      limit: 10,
      chargeBeeDialog: false,
      chargeBeeLogs: [],
      assistantNameDialog: false,
      changeAssistantName: "",
      metaAdsCredit: 0,
      nameConfirmationDialog: false,
      currencyDialog: false,
      applied: {
        createdAt: {
          startDate: null,
          endDate: null,
          focusedInput: null,
        },
      },
      birthdayCampaignEnabled:
        this.props.templates[this.props.templateIndex].specialAccess
          ?.enableBirthdayCampaign || false,
      connectFlowsEnabled:
        this.props.templates[this.props.templateIndex].specialAccess
          ?.enableConnectFlows || false,
      tabValue: 0,
      showCachedAssCode: false,
      showCachedPartnerCode: false,
      openAssistantDialog: false,
      selectedTemplateCreditAction: "ADD",
      selectedMetaCreditAction: "ADD",
      errorCurl: { event: "", curl: null },
      specialAccessKey: "",
      specialAccessValue: "",
      existingSpecialAccess: "",
      showSpecialAccess: false,
      selectedType: "",
      transferFlowId: "",
      ctwaFunnel: {},
      campaignErrorCurl: {},
      removeAdsSetupManagerDialog: false,
      isAdsMangerSetupPresent: false,
      fbConnectedAssistantId: null,
      adsSetupDeletionInProgress: false,
      campaigns: [],
      campaignStatusChangeDialog: false,
      allCampaignStatus: "ACTIVE",
      setCampaignStatusLoader: false,
      fetchCampaignStatusLoader: false,
    };
    this.anchorRef = createRef(null);
  }

  toggleAssistantDialog = () => {
    this.setState({ openAssistantDialog: !this.state.openAssistantDialog });
  };

  openChargebeeDialog = () => {
    this.setState({ chargeBeeDialog: true });
  };
  toggleBvbpDialog = () => {
    this.setState({ bvbpDialog: !this.state.bvbpDialog });
  };

  closeChargebeeDialog = () => {
    this.setState({ chargeBeeDialog: false });
  };

  openCurrencyDialog = () => {
    this.setState({ currencyDialog: true });
  };

  closeCurrencyDialog = () => {
    this.setState({ currencyDialog: false });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: parseInt(e.target.value) });
  };

  handleFlowConfigurationInput = (e) => {
    this.setState({
      flowConfiguration: {
        ...this.state.flowConfiguration,
        [e.target.name]: parseInt(e.target.value),
      },
    });
  };

  handleObjectKeyInput = (parentKey, e) => {
    this.setState({
      [parentKey]: {
        ...this.state[parentKey],
        [e.target.name]: e.target.value,
      },
    });
  };

  openAssistantNameDialog = () => {
    this.setState({ assistantNameDialog: true });
  };

  closeAssistantNameDialog = () => {
    this.setState({ assistantNameDialog: false });
  };

  updateAssistantName = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const changeProjectName = this.state.changeAssistantName;
    axios
      .post(URL + "/superadmin/updateAssistantName", {
        changeProjectName,
        assistantId,
      })
      .then((response) => {
        assistant.assistantName = changeProjectName;
        this.props.setTemplate(assistant);
        this.setState({
          alert: true,
          alertMsg: "Project Name updated!",
          alertSeverity: "success",
          changeAssistantName: "",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: error?.response?.data || "Unable to update project name",
          alertSeverity: "error",
          changeAssistantName: "",
        });
      });
  };

  updateProjectCurrency = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant?._id;
    let templateCredit = assistant?.templateCredit;
    const currency = assistant?.currency === "USD" ? "INR" : "USD";
    currency === "USD"
      ? (templateCredit = templateCredit / 80)
      : (templateCredit = templateCredit * 80);

    axios
      .post(URL + "/superadmin/updateCurrency", {
        currency,
        assistantId,
      })
      .then((response) => {
        assistant.currency = currency;
        assistant.templateCredit = templateCredit;
        this.props.setTemplate(assistant);
        this.setState({
          alert: true,
          alertMsg: "Currency updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update project currency",
          alertSeverity: "error",
        });
      });
  };

  handleNumberInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSpecialAcessKey = (e) => {
    this.setState({ specialAccessKey: e.target.value });
  };

  handleSpecialAcessValue = (e) => {
    this.setState({ specialAccessValue: e.target.value });
  };

  addSpecialAccess = (e) => {
    let { specialAccessKey, specialAccessValue, selectedType } = this.state;
    const assistant = this.props.templates[this.props.templateIndex];
    if (selectedType === "Object") {
      specialAccessValue = JSON.parse(specialAccessValue);
    } else if (selectedType === "Boolean") {
      if (specialAccessValue === "true") {
        specialAccessValue = true;
      } else {
        specialAccessValue = false;
      }
    }
    let fields = {
      specialAccess: {
        ...(assistant?.specialAccess || null),
        [specialAccessKey]: specialAccessValue,
      },
    };
    const queryObj = {
      assistantId: assistant._id,
      fields,
      dailyTemplateLimit: assistant.dailyTemplateLimit,
    };
    axios
      .post(URL + "/superadmin/set-assistant-field", queryObj)
      .then((res) => {
        this.setState({
          alert: true,
          alertMsg: "special key added successfully!",
          alertSeverity: "success",
          specialAccessKey: "",
          specialAccessValue: "",
          selectedType: "",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: error || "something went wrong!",
          alertSeverity: "error",
        });
      });
  };

  updateTemplateCredit = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    // console.log({status: e.target.checked});
    let amount = this.state.templateCredit * 100000;
    if (this.state.selectedTemplateCreditAction !== "ADD") {
      amount = -1 * amount;
    }
    const totalTemplateCredit = template.templateCredit
      ? template.templateCredit + amount
      : amount;
    this.setTemplateInDb({
      templateCredit: amount,
      paymentRemarks: this.state.paymentRemarks,
    })
      .then((response) => {
        template.templateCredit = totalTemplateCredit;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Template credit updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to add template credit",
          alertSeverity: "error",
        });
      });
  };

  updateMetaCredit = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    let amount = this.state.metaAdsCredit * 100000;
    if (this.state.selectedMetaCreditAction !== "ADD") {
      amount = -1 * amount;
    }
    const totalMetaCredit = template.metaAdsCredit
      ? template.metaAdsCredit + amount
      : amount;
    this.setTemplateInDb({
      metaAdsCredit: amount,
      metaCreditRemarks: this.state.metaCreditRemarks,
    })
      .then((response) => {
        template.metaAdsCredit = totalMetaCredit;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Meta credit updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to add Meta credit",
          alertSeverity: "error",
        });
      });
  };

  openConfirmNameDialog = () => {
    this.setState({ nameConfirmationDialog: true });
  };

  closeConfirmNameDialog = () => {
    this.setState({ nameConfirmationDialog: false });
  };

  updateMinAllowedBalance = (e) => {
    const template = this.props.templates[this.props.templateIndex];
    const amount = this.state.minAllowedBalance * 100000;
    this.setTemplateInDb({
      minAllowedBalance: amount,
    })
      .then((response) => {
        template.minAllowedBalance = amount;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Min Balance Allowance updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update min balance allowance",
          alertSeverity: "error",
        });
      });
  };
  updateApiKey = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    const apiKey360 = this.state.apiKey360;

    this.setTemplateInDb({
      apiKey360: apiKey360,
    })
      .then((response) => {
        template.apiKey360 = apiKey360;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "API Key updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to add API Key",
          alertSeverity: "error",
        });
      });
  };
  updateVerificationStatus = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    // console.log({status: e.target.checked});
    const checked = e.target.checked;

    this.setTemplateInDb({
      isWhatsappVerified: checked,
    })
      .then((response) => {
        template.isWhatsappVerified = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Assistant verified status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update verified status",
          alertSeverity: "error",
        });
      });
  };

  getMessagingLimit = () => {
    const result = Tier_Limit.filter((e) => e.name === this.state.messageLimit);
    return result[0].value;
  };

  registerWithPin = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { pin } = this.state;
    axios
      .post(URL + "/superadmin/register-with-pin", { assistantId, pin })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        const message =
          error?.response?.data?.message ||
          error.messsage ||
          "Something went wrong. Could not register";

        this.setState({
          alert: true,
          alertMsg: message,
          alertSeverity: "error",
          errorCurl: { event: "register", curl: error?.response?.data?.curl },
        });
      });
  };

  verifyCode = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { verifyCode } = this.state;
    axios
      .post(URL + "/superadmin/verify-code", { assistantId, verifyCode })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Could not verify",
          alertSeverity: "error",
        });
      });
  };

  triggerOtp = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { mode } = this.state;
    const payload = { assistantId, mode };
    axios
      .post(URL + "/superadmin/trigger-otp", payload)
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "OTP Sent",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "failed",
          alertSeverity: "error",
        });
      });
  };

  resetPassword = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { password } = this.state;
    axios
      .post(URL + "/superadmin/reset-password", { password, assistantId })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "failed",
          alertSeverity: "error",
        });
      });
  };

  resetAbout = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { about } = this.state;
    if (about.length > 139) {
      return this.setState({
        alert: true,
        alertMsg: "about length should be smaller than 135 characters",
        alertSeverity: "error",
      });
    }
    axios
      .post(URL + "/superadmin/update-about", { about, assistantId })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Failed",
          alertSeverity: "error",
        });
      });
  };

  updateMessagingLimit = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    let result;
    if (this.state.messageLimit) {
      result = this.getMessagingLimit();
    }
    axios
      .post(URL + "/superadmin/update-messaging-limit", { assistantId, result })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Failed",
          alertSeverity: "error",
        });
      });
  };
  syncMessagingLimit = () => {
    const { templates, templateIndex, setTemplate } = this.props;
    const template = templates[templateIndex];
    const payload = {
      wabaNumberId: template.wabaNumberId,
    };
    axios
      .get(URL + "/superadmin/sync-messaging-limit", { params: payload })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Failed",
          alertSeverity: "error",
        });
      });
  };

  updateAgentLimit = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { agentLimit } = this.state;
    axios
      .post(URL + "/superadmin/update-agent-limit", { assistantId, agentLimit })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Failed",
          alertSeverity: "error",
        });
      });
  };
  updateAttributeLimit = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { attributeLimit } = this.state;
    axios
      .post(URL + "/superadmin/update-attribute-limit", {
        assistantId,
        attributeLimit,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Failed",
          alertSeverity: "error",
        });
      });
  };

  updateTagLimit = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { tagLimit } = this.state;
    axios
      .post(URL + "/superadmin/update-tag-limit", { assistantId, tagLimit })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Failed",
          alertSeverity: "error",
        });
      });
  };

  updateQualtiyRating = (param) => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;

    let { qualityRating } = this.state;
    if (param == "sync") {
      const tempRating = this.state.wabaInfo?.find(
        (item) =>
          item.id ==
          this.props.templates[this.props.templateIndex]?.wabaNumberId
      )?.qualityRating;
      qualityRating = tempRating
        ? `${
            ["Low", "FLAGGED", "RED"].includes(tempRating)
              ? "Low"
              : ["UNFLAGGED", "Medium", "RESTRICTED", "YELLOW"].includes(
                  tempRating
                )
              ? "Medium"
              : "High"
          }`
        : "";
    }

    axios
      .post(URL + "/superadmin/update-quality-rating", {
        assistantId,
        qualityRating,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Failed",
          alertSeverity: "error",
        });
      });
  };

  openDialog = () => {
    this.setState({ openWebhookDialog: true });
  };

  closeDialog = () => {
    this.setState({ openWebhookDialog: false });
  };

  updatefbManager = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { fbManager } = this.state;
    axios
      .post(URL + "/superadmin/fbManager-status", {
        assistantId,
        fbManager,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Failed",
          alertSeverity: "error",
        });
      });
  };

  testAPINumber = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { testNumber } = this.state;
    axios
      .post(URL + "/superadmin/test-api-verification", {
        assistantId,
        testNumber,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Successfully verified",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "verification failed",
          alertSeverity: "error",
        });
      });
  };

  updateProviderType = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { providerType } = this.state;
    axios
      .post(URL + "/superadmin/update-provider-type", {
        assistantId,
        providerType,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Failed",
          alertSeverity: "error",
        });
      });
  };

  syncChannelStatus = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    axios
      .post(URL + "/superadmin/sync-channel-status", {
        assistantId,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Failed",
          alertSeverity: "error",
        });
      });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  updateWhatsAppNumber = () => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    // console.log({status: e.target.checked});
    const whatsappNumber = this.state.whatsappNumber;
    template.whatsappNumber = whatsappNumber;

    this.setTemplateInDb({
      whatsappNumber,
    })
      .then((response) => {
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Whatsapp number updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update whatsapp number",
          alertSeverity: "error",
        });
      });
  };
  updateAppName = () => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    // console.log({status: e.target.checked});
    const appName = this.state.appName;
    template.appName = appName;

    this.setTemplateInDb({
      appName,
    })
      .then((response) => {
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Whatsapp app name updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update whatsapp app name",
          alertSeverity: "error",
        });
      });
  };
  updateProjectStatus = () => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    // console.log({status: e.target.checked});
    const projectStatus = this.state.projectStatus;
    template.status = projectStatus;

    this.setTemplateInDb({
      status: projectStatus,
    })
      .then((response) => {
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Project status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update project status",
          alertSeverity: "error",
        });
      });
  };
  updateCredit = () => {
    let template = { ...this.props.templates[this.props.templateIndex] };
    const amount =
      template.remainingCredit + this.state.remainingCredit * 100000;
    const activePlan = this.state.activePlan || template.activePlan;
    // console.log(template.remainingCredit, this.state.remainingCredit)
    // if (this.state.activePlan) {
    const oldPlan = template.activePlan;
    console.log({ oldPlan });
    // Set plan name
    template.activePlan = activePlan;
    console.log({ oldPlan });

    // Check plan type
    if (
      [
        "BASIC_TRIAL",
        "BASIC_MONTHLY",
        "BASIC_DIWALI_MONTHLY",
        "PRO_MONTHLY",
        "ENTERPRISE_MONTHLY",
        "BASIC_YEARLY",
        "BASIC_DIWALI_YEARLY",
        "PRO_YEARLY",
        "ENTERPRISE_YEARLY",
      ].includes(activePlan)
    ) {
    } else if (activePlan.includes("BASIC_")) {
      if (!oldPlan.includes("BASIC_")) {
        template.planActivatedOn = null;
      }
      // Check if its renewal or first time
      if (template.planActivatedOn) {
        console.log(typeof template.planActivatedOn);
        console.log(template.planActivatedOn);

        template.planActivatedOn = new Date(template.planActivatedOn);
        console.log(template.planActivatedOn);
        // console.log(template.planActivatedOn);

        // template.planActivatedOn.setMonth(
        //   template.planActivatedOn.getMonth() + 8
        // );
        // console.log(template.planActivatedOn);
        // Get the current date
        const currentDate = template.planActivatedOn.getDate();
        // Set to day 1 to avoid forward
        template.planActivatedOn.setDate(1);
        // Increase month by 1
        template.planActivatedOn.setMonth(
          template.planActivatedOn.getMonth() + 1
        );
        // Get max # of days in this new month
        var daysInMonth = new Date(
          template.planActivatedOn.getYear(),
          template.planActivatedOn.getMonth() + 1,
          0
        ).getDate();
        // Set the date to the minimum of current date of days in month
        template.planActivatedOn.setDate(Math.min(currentDate, daysInMonth));
        console.log(template.planActivatedOn);
      } else {
        template.planActivatedOn = new Date();
      }
    } else {
      // v1 pricing
      template.planActivatedOn = new Date();
    }
    // }

    template.remainingCredit = amount;

    this.setTemplateInDb({
      remainingCredit: amount,
      activePlan: template.activePlan,
      planActivatedOn: template.planActivatedOn,
    })
      .then((response) => {
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Assistant status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update template status",
          alertSeverity: "error",
        });
      });
  };
  handleAutoComplete = (fieldName, value, reason) => {
    if (reason === "select-option") {
      this.setState({ [fieldName]: value });
    }
  };
  handleMark = (mark, temp) => {
    const template = { ...temp };
    if (mark) {
      // set process to new Date()
      this.setTemplateInDb({ processed: new Date() })
        .then((response) => {
          template.processed = new Date();
          this.props.setTemplate(template);
          this.setState({
            alert: true,
            alertMsg: "Template marked processed!",
            alertSeverity: "success",
          });
        })
        .catch((error) => {
          this.setState({
            alert: true,
            alertMsg: "Unable to mark, Check your internet connection.",
            alertSeverity: "error",
          });
        });
    } else {
      this.setTemplateInDb({ processed: null })
        .then((response) => {
          template.processed = null;
          this.props.setTemplate(template);
          this.setState({
            alert: true,
            alertMsg: "Template marked un-processed!",
            alertSeverity: "success",
          });
        })
        .catch((error) => {
          this.setState({
            alert: true,
            alertMsg:
              "Unable to fulfill request, Check your internet connection.",
            alertSeverity: "error",
          });
        });
    }
  };
  setTemplateInDb = (fields) => {
    // try {
    const t = this.props.templates[this.props.templateIndex];
    const queryObj = {
      assistantId: t._id,
      fields,
      dailyTemplateLimit: t.dailyTemplateLimit,
    };
    return axios.post(URL + "/superadmin/set-assistant-field", { ...queryObj });
  };
  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };
  getAssistantDetailsFromCache = async () => {
    try {
      const { templateIndex, templates } = this.props;
      const assistant = templates[templateIndex];
      if (!assistant) {
        return;
      }
      const redisKey = "assistants/" + assistant._id;
      console.log({ redisKey });
      const { data: cachedAssistant } = await axios.post(
        URL + "/superadmin/get-credits-from-cache",
        {
          assistantId: assistant._id,
        }
      );
      console.log(cachedAssistant);
      this.setState({ cachedAssistant });
    } catch (err) {
      console.log(err);
    }
  };

  getPartnerFromCache = async () => {
    const partnerId = this.props.templates[this.props.templateIndex].partnerId;
    try {
      const cachedPartner = await axios.post(
        `${CLIENT_URL}/superadmin/update-partner-in-cache`,
        {
          partnerId,
        }
      );
      this.setState({ cachedPartner: cachedPartner });
    } catch (error) {
      console.error(error);
    }
  };

  syncTemplateMsg = async () => {
    try {
      const { templateIndex, templates } = this.props;
      const assistant = templates[templateIndex];
      if (!assistant) {
        return;
      }
      const { data: cachedAssistant } = await axios.post(
        URL + "/superadmin/sync-template-messages",
        {
          assistantId: assistant._id,
        }
      );
      console.log(cachedAssistant);
      this.setState({
        alert: true,
        alertMsg: "Template msg synched",
        alertSeverity: "success",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        alert: true,
        alertMsg: "Unable update template msg",
        alertSeverity: "error",
      });
    }
  };

  migrateTemplateMsg = async () => {
    try {
      const { templateIndex, templates } = this.props;
      const assistant = templates[templateIndex];
      if (!assistant) {
        return;
      }
      const { data: cachedAssistant } = await axios.post(
        URL + "/superadmin/migrate-all-templates",
        {
          assistantId: assistant._id,
        }
      );
      console.log(cachedAssistant);
      this.setState({
        alert: true,
        alertMsg: "Template msg migrated",
        alertSeverity: "success",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        alert: true,
        alertMsg: "Unable migrate template msg",
        alertSeverity: "error",
      });
    }
  };

  enableCampaignAnalytics = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;

    this.setTemplateInDb({
      campaignAnalyticsEnabled: checked,
    })
      .then((response) => {
        template.campaignAnalyticsEnabled = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "campaign analytics status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update campaign analytics status",
          alertSeverity: "error",
        });
      });
  };
  updateMigrateStatus = (e) => {
    let template = this.props.templates[this.props.templateIndex];

    this.setTemplateInDb({
      providerType: "v2",
      wabaNumberId: "",
      wabaProgressStep: 0,
    })
      .then((response) => {
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: " Migration status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update migration status",
          alertSeverity: "error",
        });
      });
  };
  enableClickTracking = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;

    this.setTemplateInDb({
      clickTrackingEnabled: checked,
    })
      .then((response) => {
        template.clickTrackingEnabled = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Click tracking status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update click tracking status",
          alertSeverity: "error",
        });
      });
  };

  enableCampaignScheduling = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;

    this.setTemplateInDb({
      campaignSchedulingEnabled: checked,
    })
      .then((response) => {
        template.campaignSchedulingEnabled = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "campaign scheduling status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update campaign scheduling status",
          alertSeverity: "error",
        });
      });
  };

  enableMetaAdvanceTargeting = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;

    this.setTemplateInDb({
      metaAdvanceTargetingEnabled: checked,
    })
      .then((response) => {
        template.metaAdvanceTargetingEnabled = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Meta Ads status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update Meta Ads status",
          alertSeverity: "error",
        });
      });
  };

  updateWhatsappPayDetails = async (key, value) => {
    let template = this.props.templates[this.props.templateIndex];
    let { orderCheckoutDetails, _id, clientId } = template || {};

    if (isEmpty(orderCheckoutDetails)) {
      orderCheckoutDetails = {};
    }

    if (key.includes(".")) {
      const [first, sec] = key.split(".");
      if (isEmpty(orderCheckoutDetails[first])) {
        orderCheckoutDetails[first] = {};
      }
      orderCheckoutDetails[first][sec] = value;
    } else {
      orderCheckoutDetails[key] = value;
    }

    if (orderCheckoutDetails?.paymentTemplateEnabled === "APPROVED") {
      const {
        data: { token },
      } = await axios.post(
        `${URL}/superadmin/generate-any-token`,
        {
          _id: clientId,
          type: "agent",
        },
        {
          headers: {
            "x-aisensy-admin-token-key": ADMIN_KEY,
          },
        }
      );
      await axios
        .post(
          URL + "/api/get-matching-templates-with-status",
          {
            assistantId: _id,
            tag: "razorpaypaymentlink",
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(async (response) => {
          const templates = response.data || [];
          if (!isEmpty(templates)) {
            const template = templates[0];
            await axios
              .post(
                `${URL}/api/create-template`,
                {
                  assistantId: _id,
                  ...template,
                },
                {
                  headers: {
                    Authorization: token,
                  },
                }
              )
              .then(async (value) => {
                const campaign = {
                  status: "",
                  type: "API",
                  name: "Razorpay Payment Link",
                  payload: { templateName: template.name },
                };
                const url = `${CAMPAIGN_URL}/campaign/create-api-campaign`;
                const body = {
                  campaign,
                  assistantId: _id,
                  createdBy: "system_rzp",
                  skipTemplateApproval: true,
                };
                await axios
                  .post(url, body, {
                    headers: {
                      Authorization: token,
                    },
                  })
                  .then(async (value) => {
                    const url = `${INTEGRATION_SERVER_URL}/razorpay/t1/integration/event-mapping/add`;
                    const body = {
                      assistantId: _id,
                      name: "Payment link",
                      eventName: "payment_link.created",
                      templateParams: template.templateParams,
                      campaignName: "Razorpay Payment Link",
                      media: null,
                      fallbackParameters: {},
                    };
                    await axios
                      .post(url, body, {
                        headers: {
                          Authorization: token,
                        },
                      })
                      .catch((error) => {
                        console.log({ error });
                      });
                  })
                  .catch((error) => {
                    console.log({ error });
                  });
              })
              .catch((eror) => {
                this.setState({
                  alert: true,
                  alertMsg: "Unable to update Whatsapp Pay",
                  alertSeverity: "error",
                });
              });
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({ err: JSON.stringify(err), isLoading: false });
        });
    }

    this.setTemplateInDb({
      orderCheckoutDetails,
    })
      .then((response) => {
        template.orderCheckoutDetails = orderCheckoutDetails;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Whatsapp Pay Updated",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update Whatsapp Pay",
          alertSeverity: "error",
        });
      });
  };

  updateAssistantRateLimit = () => {
    let template = this.props.templates[this.props.templateIndex];
    const { assistantApiLimit } = this.state;

    this.setTemplateInDb({
      apiLimit: assistantApiLimit,
    })
      .then((response) => {
        template.apiLimit = assistantApiLimit;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Rate Limit updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update Rate Limit",
          alertSeverity: "error",
        });
      });
  };

  enableEcom = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;

    this.setTemplateInDb({
      isEcom: checked,
    })
      .then((response) => {
        template.isEcom = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Ecom status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update Ecom status",
          alertSeverity: "error",
        });
      });
  };

  enableFlowOrderFlow = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;

    this.setTemplateInDb({
      isOrderFlowEnabled: checked,
    })
      .then((response) => {
        template.isOrderFlowEnabled = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Order Flow Enabled status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update Order Flow status",
          alertSeverity: "error",
        });
      });
  };
  updateDisplayName = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const { displayNameVerified } = this.state;
    axios
      .post(URL + "/superadmin/displayname-status", {
        assistantId,
        displayNameVerified,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: "Success",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Failed",
          alertSeverity: "error",
        });
      });
  };
  enableFilterFreeze = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;

    this.setTemplateInDb({
      filterFreezeEnabled: checked,
    })
      .then((response) => {
        template.filterFreezeEnabled = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "filter freeze status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update filter freeze status",
          alertSeverity: "error",
        });
      });
  };

  enableChatSearchable = async (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;
    this.setState({ isChatScriptingRunning: true });
    await axios.post(URL + "/superadmin/chat-search-scripting", {
      assistantId: template._id,
      isSearchable: checked,
    });
    this.setTemplateInDb({
      chatSearchEnabled: checked,
    })
      .then((response) => {
        template.chatSearchEnabled = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Chat Searchable status updated!",
          alertSeverity: "success",
          isChatScriptingRunning: false,
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update chat searchable status",
          alertSeverity: "error",
          isChatScriptingRunning: false,
        });
      });
  };

  enableCSVScheduleEnabled = async (event) => {
    try {
      const { templates, templateIndex, setTemplate } = this.props;
      const template = templates[templateIndex];
      const { checked } = event.target;
      await this.setTemplateInDb({ csvScheduleEnabled: checked });
      template.csvScheduleEnabled = checked;
      setTemplate(template);
      this.setState({
        alert: true,
        alertSeverity: "success",
        alertMsg: "Updated CSV Campaign Schedule",
      });
    } catch (error) {
      console.log({ error });
      this.setState({
        alert: true,
        alertSeverity: "error",
        isChatScriptingRunning: false,
        alertMsg: "failed to update CSV Campaign Schedule",
      });
    }
  };

  optReporting = async (event) => {
    try {
      const { templates, templateIndex, setTemplate } = this.props;
      const template = templates[templateIndex];
      const { checked } = event.target;
      await this.setTemplateInDb({ optReportingEnabled: checked });
      template.optReportingEnabled = checked;
      setTemplate(template);
      this.setState({
        alert: true,
        alertSeverity: "success",
        alertMsg: "Updated opt-in/out reporting status",
      });
    } catch (error) {
      console.log({ error });
      this.setState({
        alert: true,
        alertSeverity: "error",
        isChatScriptingRunning: false,
        alertMsg: "Failed to update opt-in/out reporting status",
      });
    }
  };

  skipBalanceCheck = async (event) => {
    try {
      const { templates, templateIndex, setTemplate } = this.props;
      const template = templates[templateIndex];
      const { checked } = event.target;
      await this.setTemplateInDb({ skipBalanceCheck: checked });
      template.skipBalanceCheck = checked;
      setTemplate(template);
      this.setState({
        alert: true,
        alertSeverity: "success",
        alertMsg: "Updated Balance Check!",
      });
    } catch (error) {
      console.log({ error });
      this.setState({
        alert: true,
        alertSeverity: "error",
        isChatScriptingRunning: false,
        alertMsg: "Failed to update Balance Check",
      });
    }
  };

  updateWabaStep = async (event) => {
    try {
      const { templates, templateIndex, setTemplate } = this.props;
      const template = templates[templateIndex];

      await this.setTemplateInDb({
        wabaProgressStep: this.state.wabaProgressStep,
      });
      template.wabaProgressStep = this.state.wabaProgressStep;
      setTemplate(template);
      this.setState({
        alert: true,
        alertSeverity: "success",
        alertMsg: "Updated WABA Progress Step!",
      });
    } catch (error) {
      console.log({ error });
      this.setState({
        alert: true,
        alertSeverity: "error",
        alertMsg: "Failed to update WABA Progress Step!",
      });
    }
  };

  updateScheduledCampaignLimit = async (event) => {
    try {
      const { templates, templateIndex, setTemplate } = this.props;
      const template = templates[templateIndex];

      await this.setTemplateInDb({
        scheduleCampaignLimit: this.state.scheduleCampaignLimit,
      });

      template.scheduleCampaignLimit = this.state.scheduleCampaignLimit;
      setTemplate(template);
      this.setState({
        alert: true,
        alertSeverity: "success",
        alertMsg: "Updated scheduled campaign limit!",
      });
    } catch (error) {
      console.log({ error });
      this.setState({
        alert: true,
        alertSeverity: "error",
        alertMsg: "Failed to updated scheduled campaign limit!",
      });
    }
  };

  openBillingDialog = () => {
    this.setState({ billingProcessDialog: true });
  };

  closeBillingDialog = () => {
    this.setState({ billingProcessDialog: false });
  };

  setOngoingBilling = async (event) => {
    try {
      const { templates, templateIndex, setTemplate } = this.props;
      const template = templates[templateIndex];

      await this.setTemplateInDb({
        ongoingBillingProcess: null,
      });

      template.ongoingBillingProcess = null;
      setTemplate(template);
      this.setState({
        alert: true,
        alertSeverity: "success",
        alertMsg: "Billing Process set to Null",
      });
    } catch (error) {
      console.log({ error });
      this.setState({
        alert: true,
        alertSeverity: "error",
        alertMsg: "Failed to update Billing Process",
      });
    }
  };

  updateWabaNumberId = async (event) => {
    try {
      const { templates, templateIndex, setTemplate } = this.props;
      const template = templates[templateIndex];

      await this.setTemplateInDb({
        wabaNumberId: this.state.wabaNumberId,
      });
      template.wabaNumberId = this.state.wabaNumberId;
      setTemplate(template);
      this.setState({
        alert: true,
        alertSeverity: "success",
        alertMsg: "Updated WABA Number id",
      });
    } catch (error) {
      console.log({ error });
      this.setState({
        alert: true,
        alertSeverity: "error",
        alertMsg: "Failed to update WABA Number id",
      });
    }
  };

  updateWabaAppId = async (event) => {
    try {
      const { templates, templateIndex, setTemplate } = this.props;
      const template = templates[templateIndex];

      await this.setTemplateInDb({
        wabaAppId: this.state.wabaAppId,
      });
      template.wabaAppId = this.state.wabaAppId;
      setTemplate(template);
      this.setState({
        alert: true,
        alertSeverity: "success",
        alertMsg: "Updated WABA App id",
      });
    } catch (error) {
      console.log({ error });
      this.setState({
        alert: true,
        alertSeverity: "error",
        alertMsg: "Failed to update WABA App id",
      });
    }
  };

  fetchPartnerDetails = async () => {
    const partnerID = this.props.templates[this.props.templateIndex].partnerId;
    try {
      const response = await axios.get(
        URL +
          `/partner-apis/v1/partner/get-partner-details?partnerId=${partnerID}`
      );
      return response.data.whiteLabelUrl;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  loginAsOwner = async (value) => {
    const clientId = this.props.templates[this.props.templateIndex].clientId;
    const assistantId = this.props.templates[this.props.templateIndex]._id;

    try {
      const {
        data: { token },
      } = await axios.post(
        `${URL}/superadmin/generate-any-token`,
        { _id: clientId, assistantId: assistantId, type: "agent" },
        {
          headers: {
            "x-aisensy-admin-token-key": ADMIN_KEY,
          },
        }
      );

      switch (value) {
        case "owner":
          window.open(`${APP_URL}/login?token=${token}`, "_blank");
          break;
        case "partner": {
          const whiteLabelUrl = await this.fetchPartnerDetails();
          window.open(`${whiteLabelUrl}/login?token=${token}`, "_blank");
          break;
        }
        case "copy": {
          navigator.clipboard
            .writeText(`${APP_URL}/login?token=${token}`)
            .then(() => {
              window.alert("Login URL copied successfully");
            })
            .catch((error) => {
              console.error("Unable to copy text to clipboard:", error);
            });
        }
      }
    } catch (error) {}
  };

  loginIntoDashboard = async () => {
    const partnerId = this.props.templates[this.props.templateIndex].partnerId;
    try {
      const {
        data: { token },
      } = await axios.post(
        `${CLIENT_URL}/superadmin/generate-any-token`,
        {
          _id: partnerId,
          type: "partner",
        },
        {
          headers: {
            "x-aisensy-admin-token-key": ADMIN_KEY,
          },
        }
      );
      window.open(`${PARTNER_APP_URL}/login?token=${token}`, "_blank");
    } catch (error) {}
  };

  handleClick = () => {
    const { selectedIndex } = this.state;
    if (selectedIndex === 0) {
      this.loginAsOwner("owner");
    } else if (selectedIndex === 1) {
      this.loginAsOwner("partner");
    } else if (selectedIndex === 2) {
      this.loginIntoDashboard();
    } else if (selectedIndex === 3) {
      this.loginAsOwner("copy");
    }
  };

  handleMenuItemClick = (event, index) => {
    this.setState({ selectedIndex: index, open: false }, () => {
      this.handleClick(); // Trigger the selected action immediately after selecting an option
    });
  };

  handleHoverOpen = () => {
    this.setState({ open: true });
  };

  handleHoverClose = (event) => {
    if (
      this.anchorRef.current.contains(event.target) ||
      (this.popperNode && this.popperNode.contains(event.target))
    ) {
      return;
    }
    this.setState({ open: false });
  };
  setPopperNode = (node) => {
    this.popperNode = node;
  };

  campaignReportingAllowed = async (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;
    this.setTemplateInDb({
      campaignReportingAllowed: checked,
    })
      .then((response) => {
        template.campaignReportingAllowed = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Campaign reporting status updated!",
          alertSeverity: "success",
          isChatScriptingRunning: false,
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update campaign reporting status",
          alertSeverity: "error",
          isChatScriptingRunning: false,
        });
      });
  };

  enableFlow = async (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;

    this.setTemplateInDb({
      flowConfiguration: {
        ...template.flowConfiguration,
        isEnabled: checked,
        flowCount: template.flowConfiguration?.flowCount || 5,
        activeFlowCount: template.flowConfiguration?.flowCount || 5,
      },
    })
      .then((response) => {
        template.flowConfiguration = {
          ...template.flowConfiguration,
          isEnabled: checked,
          flowCount: template.flowConfiguration?.flowCount || 5,
          activeFlowCount: template.flowConfiguration?.flowCount || 5,
        };
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Flow status updated!",
          alertSeverity: "success",
          isChatScriptingRunning: false,
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update flow status",
          alertSeverity: "error",
          isChatScriptingRunning: false,
        });
      });
  };

  updateFlowObject = async (e) => {
    let template = this.props.templates[this.props.templateIndex];

    this.setTemplateInDb({
      flowConfiguration: {
        ...template.flowConfiguration,
        isEnabled:
          this.state?.flowConfiguration?.isEnabled ||
          template?.flowConfiguration?.isEnabled,
        flowCount: this.state?.flowConfiguration?.flowCount || 5,
        activeFlowCount: this.state?.flowConfiguration?.activeFlowCount || 5,
      },
    })
      .then((response) => {
        template.flowConfiguration = {
          ...template.flowConfiguration,
          isEnabled: this.state.flowConfiguration?.isEnabled,
          flowCount: this.state.flowConfiguration?.flowCount || 5,
          activeFlowCount: this.state.flowConfiguration?.activeFlowCount || 5,
        };
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Flow status updated!",
          alertSeverity: "success",
          isChatScriptingRunning: false,
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update flow status",
          alertSeverity: "error",
          isChatScriptingRunning: false,
        });
      });
  };

  updateWabaTpt = async () => {
    let template = this.props.templates[this.props.templateIndex];

    try {
      await this.setTemplateInDb({
        wabaTpt: this.state.wabaTpt,
      });

      await axios.put(`${CORE_API_URL}/v1/throughput`, {
        phoneId: template.wabaNumberId,
        throughput: WABATPT_TIERS_THROUGHTPUT[this.state.wabaTpt] || 40,
        type: "outgoing",
      });

      template.wabaTpt = this.state.wabaTpt;
      this.props.setTemplate(template);
      this.setState({
        alert: true,
        alertMsg: "Waba Throughut Updated",
        alertSeverity: "success",
        isChatScriptingRunning: false,
      });
    } catch (error) {
      this.setState({
        alert: true,
        alertMsg: "Unable to update Waba Throughut",
        alertSeverity: "error",
        isChatScriptingRunning: false,
      });
    }
  };

  updateWabaStatusTpt = async () => {
    let template = this.props.templates[this.props.templateIndex];

    try {
      await this.setTemplateInDb({
        wabaStatusTpt: this.state.wabaStatusTpt,
      });

      await axios.put(`${CORE_API_URL}/v1/throughput`, {
        phoneId: template.wabaAppId,
        throughput: WABATPT_TIERS_THROUGHTPUT[this.state.wabaStatusTpt] || 150,
        type: "incoming",
      });

      template.wabaStatusTpt = this.state.wabaStatusTpt;
      this.props.setTemplate(template);
      this.setState({
        alert: true,
        alertMsg: "Waba Status Throughut Updated",
        alertSeverity: "success",
        isChatScriptingRunning: false,
      });
    } catch (error) {
      this.setState({
        alert: true,
        alertMsg: "Unable to update Waba Status Throughut",
        alertSeverity: "error",
        isChatScriptingRunning: false,
      });
    }
  };

  updateWebhook = async () => {
    try {
      const response = await axios.post(
        `${URL}/superadmin/update-assistant-webhook`,
        {
          assistantId: this.props.templates[this.props.templateIndex]._id,
          webhookUrl: this.state.directApiWebookUrl,
        }
      );
      const { directApikey, directApiWebookUrl } = response.data;
      this.setState({
        directApikey,
        directApiWebookUrl,
      });
      this.setState({
        alert: true,
        alertMsg: "Webhook updated",
        alertSeverity: "success",
      });
    } catch (error) {
      this.setState({
        alert: true,
        alertMsg: "Unable to update webhook",
        alertSeverity: "error",
      });
    }
  };

  updateWebhookAddon = async (event) => {
    // const assistant = this.props.templates[this.props.templateIndex];
    // const partnerAddons = assistant?.addons || {};
    // const newAddons = {
    //   ...partnerAddons,
    //   [event.target.name]: event.target.checked,
    // };
    // const addons = Object.keys(newAddons).filter((key) => newAddons[key]);
    // const assistantId = assistant._id;
    // const payload = {
    //   assistantId,
    //   addons,
    // };
    // axios.post(URL + "/superadmin/update-webhook", payload).then((res) => {
    //   this.setState({
    //     webhookAddon: !this.state.webhookAddon,
    //     alert: true,
    //     alertMsg: !this.state.webhookAddon
    //       ? "webhook enabled successfully"
    //       : "webhook disabled successfully",
    //     alertSeverity: "success",
    //   }).catch((error) => {
    //     this.setState({
    //       alert: true,
    //       alertMsg: !this.state.webhookAddon
    //         ? "webhook enabled failed"
    //         : "webhook disabled success",
    //       alertSeverity: "error",
    //     });
    //   });
    // });
  };

  migratePhoneNumber = async () => {
    try {
      const { migrationNumber, srcAssistantId } = this.state;
      await axios.patch(`${URL}/superadmin/migrate-project-number`, {
        phoneNumber: migrationNumber,
        srcAssistantId,
        destAssistantId: this.props.templates[this.props.templateIndex]._id,
      });
      this.setState({
        alert: true,
        alertMsg: "Migration successfull!",
        alertSeverity: "success",
      });
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.messsage ||
        "Something went wrong. Cannot migrate this number to this project";
      this.setState({
        alert: true,
        alertMsg: message,
        alertSeverity: "error",
      });
    }
  };

  migrateProjectToPartner = async () => {
    try {
      const { partnerId, defaultPlan, activationDate } = this.state;
      await axios.patch(`${URL}/superadmin/migrate-project-to-partner`, {
        projectId: this.props.templates[this.props.templateIndex]._id,
        partnerId,
        defaultPlan,
        activationDate,
      });
      this.setState({
        alert: true,
        alertMsg: "Migration successfull!",
        alertSeverity: "success",
      });
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.messsage ||
        "Something went wrong. Cannot migrate this project to Partner";
      this.setState({
        alert: true,
        alertMsg: message,
        alertSeverity: "error",
      });
    }
  };

  migratePartnerProjectToAisensy = async () => {
    try {
      await axios.patch(
        `${URL}/superadmin/migrate-partner-project-to-aisensy`,
        {
          projectId: this.props.templates[this.props.templateIndex]._id,
        }
      );
      this.setState({
        alert: true,
        alertMsg: "Migration successfull!",
        alertSeverity: "success",
      });
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.messsage ||
        "Something went wrong. Cannot migrate this Partner project to AiSensy!";
      this.setState({
        alert: true,
        alertMsg: message,
        alertSeverity: "error",
      });
    }
  };

  handleUrlChange = (event, index, webhookIndex) => {
    const updatedApp = [...this.state.privateApps];
    updatedApp[index].webhooks[webhookIndex].webhookUrl = event.target.value;
    this.setState({ privateApps: updatedApp });
  };

  handleCheckboxChange = (index, webhookIndex, topicLabel) => {
    const updatedApp = [...this.state.privateApps];

    if (
      updatedApp[index]?.webhooks[webhookIndex]?.topicsArray?.includes(
        topicLabel
      )
    ) {
      // If yes, remove it
      updatedApp[index].webhooks[webhookIndex].topicsArray = updatedApp[
        index
      ].webhooks[webhookIndex].topicsArray.filter(
        (label) => label !== topicLabel
      );
    } else {
      // If no, add it
      updatedApp[index].webhooks[webhookIndex].topicsArray.push(topicLabel);
    }

    this.setState({ privateApps: updatedApp });
  };

  createPrivateApp = async () => {
    try {
      const { data } = await axios.post(`${URL}/superadmin/create-privateapp`, {
        assistantId: this.props.templates[this.props.templateIndex]._id,
        password: this.generateRandomPassword(),
      });

      const newPrivateApp = {
        privateAppId: data._id,
        webhooks: [{ topicsArray: [], webhookUrl: "", webhookId: "" }],
        apiPassword: "",
      };

      this.setState((prevState) => ({
        privateApps: [...prevState.privateApps, newPrivateApp],
      }));

      this.setState({
        alert: true,
        alertMsg: "Private App created",
        alertSeverity: "success",
      });
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.messsage ||
        "Something went wrong. Cannot create private app!";
      this.setState({
        alert: true,
        alertMsg: message,
        alertSeverity: "error",
      });
    }
  };

  generateRandomPassword = () => {
    const length = 21;
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard", err);
      });
  };

  handleClickOpen = (index) => {
    this.setState({ open: true, currentIndex: index });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  reGeneratePassword = async (index) => {
    try {
      const { data } = await axios.post(
        `${URL}/superadmin/re-generate-password`,
        {
          privateAppId: this.state.privateApps[index].privateAppId,
          assistantId: this.props.templates[this.props.templateIndex]._id,
        }
      );

      const updatedPrivateApps = [...this.state.privateApps];
      updatedPrivateApps[index].apiPassword = data;

      this.setState({
        alert: true,
        alertMsg: "Successfully Regenerated Password",
        alertSeverity: "success",
      });
    } catch (error) {
      this.setState({
        alert: true,
        alertMsg: "Error while Regenerating Password",
        alertSeverity: "error",
      });
    }
  };

  handleDialogOpen = () => {
    this.setState({ isDialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  createWebhook = async (index, webhookIndex) => {
    try {
      const { data } = await axios.post(
        `${URL}/superadmin/create-or-update-webhook`,
        {
          assistantId: this.props.templates[this.props.templateIndex]._id,
          privateAppId: this.state.privateApps[index]?.privateAppId,
          topicsArr:
            this.state.privateApps[index]?.webhooks[webhookIndex]?.topicsArray,
          webhookUrl:
            this.state.privateApps[index]?.webhooks[webhookIndex]?.webhookUrl,
          webhookId:
            this.state.privateApps[index]?.webhooks[webhookIndex]?.webhookId ||
            "",
        }
      );

      const updatedPrivateApps = [...this.state.privateApps];
      if (data.created) {
        updatedPrivateApps[index].webhooks[webhookIndex].webhookId =
          data.webhookId;
        updatedPrivateApps[index].webhooks[webhookIndex].sharedSecret =
          data.sharedSecret;
      }

      this.setState({
        privateApps: updatedPrivateApps,
        alert: true,
        alertMsg:
          data.created === true ? "Webhook created!" : "Webhook updated!",
        alertSeverity: "success",
      });
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.messsage ||
        "Something went wrong. Cannot create webhook!";
      this.setState({
        alert: true,
        alertMsg: message,
        alertSeverity: "error",
      });
    }
  };

  fetchChargeBeeLogs = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;

    axios
      .post(URL + "/superadmin/fetch-chargeBee-logs", {
        assistantId,
      })

      .then((response) => {
        this.setState({
          chargeBeeLogs: response?.data?.result,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Unable to fetch chargeBee logs",
          alertSeverity: "error",
        });
      });
  };

  fetchFBMInfo = () => {
    const payload = {
      fbBusinessId:
        this.props.templates[this.props.templateIndex]?.fbBusinessId,
    };
    axios
      .get(`${URL}/superadmin/fetch-fbm-meta-info`, {
        params: payload,
      })
      .then((res) => {
        this.setState({
          FBMInfo: {
            name: res?.data?.businessInfo?.name,
            status: res?.data?.businessInfo?.verification_status,
          },
          fbmInfoLoading: false,
        });
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          FBMInfo: {},
          fbmInfoLoading: false,
        });
      });
  };
  fetchCreditInfo = () => {
    const payload = {
      fbBusinessId:
        this.props.templates[this.props.templateIndex]?.fbBusinessId,
      wabaAppId: this.props.templates[this.props.templateIndex]?.wabaAppId,
      assistantId: this.props.templates[this.props.templateIndex]?._id,
      allocationId:
        this.props.templates[this.props.templateIndex]?.allocationId,
    };
    axios
      .get(`${URL}/superadmin/verify-credit-line`, {
        params: payload,
      })
      .then((res) => {
        this.setState({
          creditLineInfo: res.data.message,
        });
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          creditLineInfo: "",
        });
      });
  };

  fetchWabaInfo = () => {
    const payload = {
      wabaAppId: this.props.templates[this.props.templateIndex]?.wabaAppId,
    };
    axios
      .get(`${URL}/superadmin/fetch-waba-meta-info`, {
        params: payload,
      })
      .then((res) => {
        const liveWabaDetails =
          res?.data?.wabaInfo?.map((data) => {
            return {
              phoneNumber: data?.display_phone_number,
              status: data?.status,
              displayName: data?.verified_name,
              qualityRating: data?.quality_rating,
              displayNameStatus: data?.name_status,
              messagingLimit: data?.messaging_limit_tier,
              id: data?.id,
            };
          }) || [];

        this.setState({
          wabaInfo: liveWabaDetails,
          wabaInfoLoading: false,
        });
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          wabaInfo: [],
          wabaInfoLoading: false,
        });
      });
  };

  componentDidMount() {
    this.fetchFBMInfo();
    this.fetchWabaInfo();
    this.fetchCreditInfo();
    this.checkAdsSetupManager();
    this.checkFbConnectedAssistant();

    this.getAssistantDetailsFromCache();
    const partner = this.props.templates[this.props.templateIndex].partnerId
      ? this.getPartnerFromCache()
      : "";
    const assistant = this.props.templates[this.props.templateIndex];
    // const addons = assistant?.addons?.WEBHOOKS;
    // this.setState({
    //   webhookAddon: addons,
    // });
    const assistantId = assistant._id;

    this.loadWebhookLogs();

    this.fetchChargeBeeLogs();

    if (!isEmpty(assistant?.orderCheckoutDetails?.paymentSlabs)) {
      this.setState({
        whatsappPaySlabs: JSON.stringify(
          assistant?.orderCheckoutDetails?.paymentSlabs,
          null,
          2
        ),
      });
    }

    axios
      .post(`${URL}/superadmin/view-super-admin-logs`, {
        assistantId,
      })
      .then((response) => {
        const newLogs = response.data.map((log) => {
          const newLog = { ...log };
          delete newLog.body.assistantId;
          if (Object.keys(newLog.body).length === 0) {
            return undefined;
          } else {
            return newLog;
          }
        });
        const filteredLogs = newLogs.filter((log) => log !== undefined);
        this.setState({
          logs: filteredLogs,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(URL + "/superadmin/get-client-details", {
        assistantId,
      })
      .then((response) => {
        this.setState({
          ownerName: response.data.email,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Owner email not found",
          alertSeverity: "error",
        });
      });

    axios
      .post(URL + "/superadmin/get-private-apps", {
        assistantId,
      })
      .then((response) => {
        const data = response.data;
        this.setState({
          privateApps: data,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Unable to fetch private Apps",
          alertSeverity: "error",
        });
      });

    this.setState({
      existingSpecialAccess:
        this.props.templates[this.props.templateIndex].specialAccess || {},
    });
  }

  loadWebhookLogs = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const webhookUrl = assistant?.directApiWebookUrl;
    const assistantId = assistant._id;

    axios
      .post(URL + "/superadmin/get-webhook-logs", {
        assistantId,
        webhookUrl,
        limit: this.state.limit,
      })

      .then((response) => {
        this.setState({
          webhookLogs: response?.data?.combinedResult,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alert: true,
          alertMsg: "Unable to fetch webhook logs",
          alertSeverity: "error",
        });
      });
  };

  migrateTemplates = async () => {
    try {
      const { migrateTemplatesFrom, migrateTemplatesTo } = this.state;

      if (!migrateTemplatesFrom || !migrateTemplatesTo) {
        return;
      }

      const url = `${URL}/superadmin/migrate-templates`;
      const body = {
        sourceId: migrateTemplatesFrom,
        destinationId: migrateTemplatesTo,
      };

      await axios.post(url, body);
    } catch (error) {
      console.log({ error });
    }
  };

  enableCtwaFunnel = async () => {
    try {
      const { affiliateId, adId } = this.state.ctwaFunnel || {};

      if (!affiliateId || !adId) {
        return;
      }

      const url = `${URL}/superadmin/wa-forms/enable-signup-form`;
      const body = {
        assistantId: this.props.templates[this.props.templateIndex]._id,
        affiliateId,
        adId,
      };

      await axios.post(url, body);
      this.setState({
        alert: true,
        alertMsg: "Flow enabled successfully!",
        alertSeverity: "success",
      });
    } catch (error) {
      console.log({ error });
      const message =
        error?.response?.data?.error?.message ||
        error.message ||
        error.errorMessage ||
        error;

      this.setState({
        alert: true,
        alertMsg: `Unable to enable CTWA Funnel: ${message}`,
        alertSeverity: "error",
      });
    }
  };

  getCampaignErrorCurl = async () => {
    try {
      const { campaignId, userNumber } = this.state.campaignErrorCurl || {};

      if (!campaignId || !userNumber) {
        return;
      }

      const url = `${URL}/superadmin/get-campaign-error-curl`;
      const body = {
        assistantId: this.props.templates[this.props.templateIndex]._id,
        campaignId,
        userNumber,
      };

      const { data } = await axios.post(url, body);
      navigator.clipboard.writeText(data.curl);
      this.setState({
        alert: true,
        alertMsg: "cURL copied to clipboard!",
        alertSeverity: "success",
      });
    } catch (error) {
      console.log({ error });
      const message =
        error?.response?.data?.error?.message ||
        error.message ||
        error.errorMessage ||
        error;

      this.setState({
        alert: true,
        alertMsg: `Unable to get cURL: ${message}`,
        alertSeverity: "error",
      });
    }
  };

  removeAdsSetupManager = async () => {
    this.setState({ adsSetupDeletionInProgress: true });
    try {
      await axios.post(`${URL}/superadmin/delete-child-bm`, {
        projectId: this.props.templates[this.props.templateIndex]._id,
      });

      this.setState({
        alert: true,
        alertMsg: "Ads Setup Manager removed successfully!",
        alertSeverity: "success",
        isAdsMangerSetupPresent: false,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.error?.message ||
        error.message ||
        error.errorMessage ||
        error;
      console.log("Error removing ads setup manager", { error }, message);

      this.setState({
        alert: true,
        alertMsg: `Unable to Remove Ads Manager Setup: ${message}`,
        alertSeverity: "error",
      });
    }
    this.setState({
      removeAdsSetupManagerDialog: false,
      adsSetupDeletionInProgress: false,
    });
  };

  checkAdsSetupManager = async () => {
    try {
      const { data } = await axios.post(
        `${URL}/superadmin/check-ads-setup-manager`,
        {
          projectId: this.props.templates[this.props.templateIndex]._id,
        }
      );

      if (data.setupExists) {
        this.setState({ isAdsMangerSetupPresent: true });
      } else {
        this.setState({ isAdsMangerSetupPresent: false });
      }
    } catch (error) {
      this.setState({ isAdsMangerSetupPresent: false });
      console.log({ error });
      const message =
        error?.response?.data?.error?.message ||
        error.message ||
        error.errorMessage ||
        error;

      this.setState({
        alert: true,
        alertMsg: `Unable Check Ads Manager Setup: ${message}`,
        alertSeverity: "error",
      });
    }
  };

  checkFbConnectedAssistant = async () => {
    try {
      const { data } = await axios.post(
        `${URL}/superadmin/check-for-connected-assistant`,
        {
          projectId: this.props.templates[this.props.templateIndex]._id,
        }
      );
      if (data.statusOk && data.setupAssistantId) {
        this.setState({ fbConnectedAssistantId: data.setupAssistantId });
      }
    } catch (error) {
      this.setState({ fbConnectedAssistantId: null });
      console.log({ error });
      const message =
        error?.response?.data?.error?.message ||
        error.message ||
        error.errorMessage ||
        error;
      this.setState({
        alert: true,
        alertMsg: `Unable Fb Connected Assistant: ${message}`,
        alertSeverity: "error",
      });
    }
  };

  checkCampaignStatus = async () => {
    this.setState({ fetchCampaignStatusLoader: true });
    try {
      const { data } = await axios.post(
        `${URL}/superadmin/check-for-active-campaigns`,
        {
          projectId: this.props.templates[this.props.templateIndex]._id,
        }
      );
      if (data.statusOk) {
        if (data.allCampaignPaused) {
          this.setState({
            pauseAllCampaignsDialog: false,
            removeAdsSetupManagerDialog: true,
            allCampaignStatus: "PAUSED",
            campaigns: data.campaigns,
          });
        } else {
          this.setState({
            pauseAllCampaignsDialog: true,
            removeAdsSetupManagerDialog: false,
            allCampaignStatus: "ACTIVE",
            campaigns: data.campaigns,
          });
        }
      }
    } catch (error) {
      this.setState({
        pauseAllCampaignsDialog: false,
        allCampaignStatus: "ACTIVE",
        campaigns: [],
      });
      console.log({ error });
      const message =
        error?.response?.data?.error?.message ||
        error.message ||
        error.errorMessage ||
        error;
      this.setState({
        alert: true,
        alertMsg: `Unable To Check Campaign Status: ${message}`,
        alertSeverity: "error",
      });
    }
    this.setState({ fetchCampaignStatusLoader: false });
  };
  setAllCampaignStatus = async () => {
    this.setState({ setCampaignStatusLoader: true });
    try {
      const desiredStatus =
        this.state.allCampaignStatus === "ACTIVE" ? "PAUSED" : "ACTIVE";
      const { data } = await axios.post(
        `${URL}/superadmin/set-all-campaign-status`,
        {
          projectId: this.props.templates[this.props.templateIndex]._id,
          desiredStatus,
        }
      );
      if (data.statusOk) {
        // this.setState({ allCampaignStatus: desiredStatus });
        await this.checkCampaignStatus();
      }
    } catch (error) {
      this.setState({
        pauseAllCampaignsDialog: false,
      });
      console.log({ error });
      const message =
        error?.response?.data?.error?.message ||
        error.message ||
        error.errorMessage ||
        error;
      this.setState({
        alert: true,
        alertMsg: `Unable Set Campaign Status: ${message}`,
        alertSeverity: "error",
      });
    }
    this.setState({ setCampaignStatusLoader: false });
  };

  disableDirectApi = async () => {
    try {
      await axios.post(`${URL}/superadmin/disable-direct-apis`, {
        projectId: this.props.templates[this.props.templateIndex]._id,
      });
      let template = this.props.templates[this.props.templateIndex];

      template.directApikey = undefined;
      this.props.setTemplate(template);
      this.setState({
        alert: true,
        alertMsg: "Direct API disable successfully!",
        alertSeverity: "success",
      });
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.messsage ||
        "Something went wrong. Cannot migrate this Partner project to AiSensy!";
      this.setState({
        alert: true,
        alertMsg: message,
        alertSeverity: "error",
      });
    }
  };

  handleDrawerOpen = async () => {
    this.setState({ isDrawerOpen: true });
  };
  handleDrawerClose = () => {
    this.setState({ isDrawerOpen: false });
  };

  fetchShopifyLogs = async (assistantId) => {
    try {
      const response = await axios.post(
        `${URL}/superadmin/latest-shopify-jobs`,
        { _id: assistantId }
      );
      if (response.status === 200 && response.data.length > 0) {
        this.setState({ shopifyLogs: response.data, isEcomDrawerOpen: true });
      } else {
        this.setState({ shopifyLogs: [], isEcomDrawerOpen: true });
      }
    } catch (error) {
      this.setState({
        alert: true,
        alertMsg: "Failed",
        alertSeverity: "error",
        shopifyLogs: [],
        isEcomDrawerOpen: true,
      });
    }
  };

  fetchWooCommerceLogs = async (assistantId) => {
    try {
      const response = await axios.post(
        `${URL}/superadmin/latest-woocommerce-logs`,
        { assistantId }
      );
      if (
        response.status === 200 &&
        (response.data.WooComOrderEvents.length > 0 ||
          response.data.WooComAbandonedEvents.length > 0)
      ) {
        this.setState({
          wooCommerceLogs: response.data,
          isEcomDrawerOpen: true,
        });
      } else {
        this.setState({
          wooCommerceLogs: { WooComOrderEvents: [], WooComAbandonedEvents: [] },
          isEcomDrawerOpen: true,
        });
      }
    } catch (error) {
      this.setState({
        wooCommerceLogs: { WooComOrderEvents: [], WooComAbandonedEvents: [] },
        isEcomDrawerOpen: true,
        alert: true,
        alertMsg: "Failed",
        alertSeverity: "error",
      });
    }
  };

  // handleToggleCollapse = (index) => {
  //   this.setState((prevState) => ({
  //     expandedLogIndex: prevState.expandedLogIndex === index ? null : index,
  //   }));
  // };

  handleToggleCollapse = (index, type) => {
    this.setState((prevState) => ({
      [type]: prevState[type] === index ? null : index,
    }));
  };

  handleWooCommerceTabChange = (event, newValue) => {
    this.setState({ selectedWooCommerceTab: newValue });
  };

  handleEcomDrawerOpen = async (template) => {
    try {
      if (template.ecomAppStore === "shopify") {
        await this.fetchShopifyLogs(template._id);
      } else if (template.ecomAppStore === "woocommerce") {
        await this.fetchWooCommerceLogs(template._id);
      }
    } catch (error) {
      console.error("Error opening e-commerce drawer:", error);
    }
  };

  handleEcomDrawerClose = () => {
    this.setState({ isEcomDrawerOpen: false });
  };

  enableDirectApi = async (mode) => {
    try {
      const { username, password } = this.state.directApiCredentials;
      if (mode === "token" && (!username || !password)) {
        this.setState({
          alert: true,
          alertMsg:
            "Please enter username and password both if you want to generate tokens",
          alertSeverity: "error",
        });
        return;
      }

      const { data } = await axios.post(
        `${URL}/superadmin/enable-direct-apis`,
        {
          username,
          password,
          projectId: this.props.templates[this.props.templateIndex]._id,
        }
      );
      let template = this.props.templates[this.props.templateIndex];

      template.directApikey = data.accessToken;
      this.props.setTemplate(template);
      this.setState({
        alert: true,
        alertMsg: "Direct API enabled successfully!",
        alertSeverity: "success",
        directApiCredentials: { ...this.state.directApiCredentials, ...data },
      });
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.messsage ||
        "Something went wrong. Cannot migrate this Partner project to AiSensy!";
      this.setState({
        alert: true,
        alertMsg: message,
        alertSeverity: "error",
      });
    }
  };

  verifyJSON = (value) => {
    let vl = null;
    try {
      vl = JSON.parse(value);
    } catch (error) {
      vl = null;
    }

    return vl;
  };

  updatePaymentSlabs = () => {
    const { whatsappPaySlabs } = this.state || {};

    const vl = this.verifyJSON(whatsappPaySlabs);
    if (vl) {
      this.updateWhatsappPayDetails("paymentSlabs", vl);
    }
  };

  openChargebee = () => {
    const assistantId = this.props.templates[this.props.templateIndex]._id;
    const clientId = this.props.templates[this.props.templateIndex].clientId;

    const ChargebeeId = clientId + "_" + assistantId;

    window.open(`${CHARGEBEE_REDIRECT_URL}/${ChargebeeId}`, "_blank");
  };

  getReport = () => {
    const startDate = this.state.applied["createdAt"].startDate;
    const endDate = this.state.applied["createdAt"].endDate;
    const { templateIndex, templates } = this.props;
    const template = templates[templateIndex];
    const currency = template?.currency;
    const assistantId = template._id;
    const name = template.assistantName;

    axios
      .post(
        `${URL}/superadmin/view-super-admin-logs`,
        { assistantId, startDate, endDate },
        { responseType: "stream" }
      )

      .then((res) => {
        const newLogs = res.data.filter(
          (log) => log?.body?.fields?.templateCredit
        );

        const headers = {
          templateCredit: "Recharge Amount",
          paymentRemarks: "Remarks",
          displayName: "Added By",
          createdAt: "Date",
        };

        const items = [...newLogs];
        const replacer = (key, value) => (value === null ? "" : value);
        const header = Object.keys(headers);
        let csv = items.map((row) =>
          header
            .map((fieldName) => {
              let value = row?.body?.fields[fieldName]
                ? row.body.fields[fieldName]
                : row[fieldName]
                ? row[fieldName]
                : null;
              if (fieldName === "createdAt") {
                value = value
                  ? new Date(value).toLocaleDateString([], {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })
                  : null;
              }

              if (fieldName === "templateCredit") {
                value = value
                  ? `${currency} ${(value / 100000).toFixed(2)}`
                  : null;
              }

              return JSON.stringify(value, replacer);
            })
            .join(",")
        );
        csv.unshift(Object.values(headers).join(","));
        csv = csv.join("\r\n");

        //Download the file as CSV
        var downloadLink = document.createElement("a");
        const blob = new Blob([csv], { type: "text/csv" });
        var url = window.URL.createObjectURL(blob);
        downloadLink.href = url;

        let filename = name
          ? `${name}_template_credit_report`
          : "template_credit_report";

        if (startDate && endDate) {
          const formattedStartDate = new Date(startDate).toLocaleDateString(
            "en-GB"
          );
          const formattedEndDate = new Date(endDate).toLocaleDateString(
            "en-GB"
          );
          filename += `_${formattedStartDate}_to_${formattedEndDate}`;
        }

        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        this.setState({
          alert: true,
          alertMsg: "Report downloaded successfully!",
          alertSeverity: "success",
        });
      })

      .catch((error) => {
        const message =
          error.response?.data?.message ||
          error.messsage ||
          "Unable to download the report :(";
        this.setState({
          alert: true,
          alertMsg: message,
          alertSeverity: "error",
        });
      });
  };

  clearDate = () => {
    const filterKey = "createdAt";
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };

  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };

  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };

  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };

  onBvbpHandler = async (data) => {
    this.setState({ businessId: data.get("end_business_id") });

    axios
      .post(`${URL}/superadmin/bvbp-apply`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            alert: true,
            alertMsg: "Applied successfully!",
            alertSeverity: "success",
          });
          this.getApplicationStatusHandler();
          this.toggleBvbpDialog();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({
          alert: true,
          alertMsg: "Something went wrong",
          alertSeverity: "error",
        });
      });
  };

  getApplicationStatusHandler = () => {
    const payload = {
      end_business_id: this.state.businessId,
    };
    if (this.state.businessId) {
      axios
        .get(`${URL}/superadmin/get-bvbp-status`, {
          params: payload,
        })
        .then((response) => {
          const lastAttempt = response.data?.data?.length;
          if (lastAttempt === 0) {
            this.setState({
              verificationStatus: "Not submitted Yet",
            });
          } else {
            const latestStatus = response.data.data.reduce((latest, record) => {
              return new Date(latest.update_time) > new Date(record.update_time)
                ? latest
                : record;
            });

            this.setState({
              verificationStatus: latestStatus.verification_status,
              rejectionReasons: latestStatus.rejection_reasons,
              bvbpCount: lastAttempt,
              bvbpApplicationResponse: response.data.data,
            });
          }
        })
        .catch((error) => {
          console.error("Error", error);
          this.setState({
            verificationStatus: "",
            alert: true,
            alertMsg: `${
              error.response.status == 400 ? "Bad Request" : error.message
            }`,
            alertSeverity: "error",
          });
        });
    }
  };

  enableBirthdayCampaign = () => {
    let obj = null;
    if (this.state.connectFlowsEnabled)
      obj = {
        enableConnectFlows: true,
      };
    this.setTemplateInDb({
      specialAccess: {
        ...obj,
        enableBirthdayCampaign: !this.state.birthdayCampaignEnabled,
      },
    });
    this.setState({
      birthdayCampaignEnabled: !this.state.birthdayCampaignEnabled,
      alert: true,
      alertMsg: "Birthday campaign status updated!",
      alertSeverity: "success",
    });
  };

  enableConnectFlows = () => {
    let obj = null;
    if (this.state.birthdayCampaignEnabled)
      obj = {
        enableBirthdayCampaign: true,
      };
    this.setTemplateInDb({
      specialAccess: {
        ...obj,
        enableConnectFlows: !this.state.connectFlowsEnabled,
      },
    });
    this.setState({
      connectFlowsEnabled: !this.state.connectFlowsEnabled,
      alert: true,
      alertMsg: "Connect Flows status updated!",
      alertSeverity: "success",
    });
  };

  togglePreVerifiedAccess = () => {
    const assistant = this.props.templates[this.props.templateIndex];
    const virtualNumberConfig = assistant.virtualNumberConfig || {};
    this.setTemplateInDb({
      virtualNumberConfig: {
        ...virtualNumberConfig,
        showPreVerified: !virtualNumberConfig.showPreVerified,
      },
    });
    this.setState({
      alert: true,
      alertMsg: "Updated!",
      alertSeverity: "success",
    });
  };

  handleTabs = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleFlowTransfer = async (deletePreviousFlow) => {
    const assistant = this.props.templates[this.props.templateIndex];
    const assistantId = assistant._id;
    const clientId = assistant.clientId;

    await axios
      .post(URL + "/superadmin/flow_transfer", {
        flowId: this.state.transferFlowId,
        assistantId,
        deletePreviousFlow,
        clientId,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertMsg: deletePreviousFlow
            ? "Flow transferred successfully!"
            : "Flow copied successfully",
          alertSeverity: "success",
          transferFlowId: "",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg:
            error.message || error.errorMessage || "Something Went Wrong!",
          alertSeverity: "error",
        });
      });
  };

  handleFlowTransferId = (event) => {
    this.setState({ transferFlowId: event.target.value });
  };

  handleDelayEnabled = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;

    this.setTemplateInDb({
      flowConfiguration: {
        ...template.flowConfiguration,
        delayEnabled: checked,
      },
    })
      .then((response) => {
        template.flowConfiguration = {
          ...template.flowConfiguration,
          delayEnabled: checked,
        };
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: checked ? "Delay enabled" : "Delay disabled",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to enable delay!",
          alertSeverity: "error",
        });
      });
  };

  handleTimeoutEnabled = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;

    this.setTemplateInDb({
      flowConfiguration: {
        ...template.flowConfiguration,
        timeoutEnabled: checked,
      },
    })
      .then((response) => {
        template.flowConfiguration = {
          ...template.flowConfiguration,
          timeoutEnabled: checked,
        };
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Timeout successfully updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update timeout!",
          alertSeverity: "error",
        });
      });
  };

  handleIsTrialClaimed = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    const checked = e.target.checked;

    this.setTemplateInDb({
      flowConfiguration: {
        ...template.flowConfiguration,
        isTrialClaimed: checked,
      },
    })
      .then((response) => {
        template.flowConfiguration = {
          ...template.flowConfiguration,
          isTrialClaimed: checked,
        };
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "trial successfully updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to claim trial!",
          alertSeverity: "error",
        });
      });
  };

  render() {
    const { classes, toggleDialog, templateIndex, templates, tenantDetails } =
      this.props;
    const {
      tabValue,
      shopifyLogs,
      isEcomDrawerOpen,
      expandedLogIndex,
      wooCommerceLogs,
      expandedWooCommerceLogIndex,
      selectedWooCommerceTab,
      open,
      selectedIndex,
    } = this.state;

    const template = templates[templateIndex];
    const currency = template?.currency;
    let isTenant = !!Object.keys(tenantDetails).length;

    const displayActivePlan =
      template.planDuration === 17 && template.activePlan === "PRO_MONTHLY"
        ? "TRIAL"
        : template.activePlan;

    return (
      <>
        <div className={classes.root}>
          <Box style={{ display: "flex" }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabValue}
              onChange={this.handleTabs}
              aria-label="Vertical tabs"
              style={{
                width: "15%",
                position: "fixed",
                top: "0",
                marginTop: "6%",
                zIndex: "2",
              }}
              classes={{ indicator: classes.indicator }}
            >
              <Tab
                label="Waba"
                style={
                  tabValue === 0
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="FB Manager"
                style={
                  tabValue === 1
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="Finance"
                style={
                  tabValue === 2
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="Platform Config"
                style={
                  tabValue === 3
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="Plan Management"
                style={
                  tabValue === 4
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="Flows"
                style={
                  tabValue === 5
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="Dev Area"
                style={
                  tabValue === 6
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="Extras"
                style={
                  tabValue === 7
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
              <Tab
                label="Meta Ads"
                style={
                  tabValue === 8
                    ? { backgroundColor: "rgb(237 226 252 / 37%)" }
                    : {}
                }
              />
            </Tabs>
          </Box>
          <Box
            px={2}
            className={classes.dialogContainer}
            style={{ marginTop: "-20px" }}
          >
            <Grid
              container
              alignItems="center"
              className={classes.dialogTopbar}
            >
              <Grid item>
                <IconButton onClick={toggleDialog} style={{ marginTop: "8px" }}>
                  <ArrowBackOutlined />
                </IconButton>
              </Grid>
              <Grid item style={{ flexGrow: 1, marginTop: "10px" }}>
                {template.assistantName}
                <IconButton
                  onClick={() => {
                    this.openAssistantNameDialog();
                  }}
                  disabled={this.props.agent.power <= 10}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Grid>

              {/* Assistant Name change Dialog */}

              <div>
                <Dialog
                  open={this.state.assistantNameDialog}
                  onClose={this.closeAssistantNameDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: { height: "20%", width: "35%" },
                  }}
                >
                  <DialogContent>
                    <DialogContentText>
                      Enter new project name:
                    </DialogContentText>
                    <TextField
                      name="changeAssistantName"
                      value={this.state.changeAssistantName}
                      onChange={this.handleChange}
                      disabled={this.props.agent.power <= 10}
                      style={{
                        width: "80%",
                        border: "1px solid ",
                        background: "white",
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.closeAssistantNameDialog}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.closeAssistantNameDialog();
                        this.openConfirmNameDialog();
                      }}
                      color="primary"
                      disabled={!this.state.changeAssistantName}
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <div>
                <Dialog
                  open={this.state.nameConfirmationDialog}
                  onClose={this.closeConfirmNameDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: { height: "18%", width: "35%" },
                  }}
                >
                  <DialogContent>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Roboto, sans-serif",
                        fontSize: "16px",
                      }}
                    >
                      Are you sure you want to change project name from <br />
                      <b>{template.assistantName}</b> to{" "}
                      <b>{this.state.changeAssistantName}</b>?
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.closeConfirmNameDialog}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.updateAssistantName();
                        this.closeConfirmNameDialog();
                      }}
                      color="primary"
                    >
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              <Grid item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "8px",
                  }}
                >
                  {this.props.templates[this.props.templateIndex].partnerId ? (
                    <Typography
                      variant="h5"
                      align="center"
                      style={{ marginTop: "10px", marginRight: "10px" }}
                    >
                      Partner ID:
                      {" " +
                        this.props.templates[this.props.templateIndex]
                          .partnerId}
                    </Typography>
                  ) : this.props.templates[this.props.templateIndex]
                      .affiliateId ? (
                    <Typography
                      variant="h5"
                      align="center"
                      style={{ marginTop: "10px", marginRight: "10px" }}
                    >
                      Affiliate ID:
                      {" " +
                        this.props.templates[this.props.templateIndex]
                          .affiliateId}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {/* View Logs Button */}
                  {(TENANT_ID && this.props.agent.power >= 10) ||
                  this.props.agent.power >= 12 ? (
                    <>
                      {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleDrawerOpen}
                      >
                        View Logs
                      </Button> */}
                    </>
                  ) : (
                    <></>
                  )}

                  {/* Owner Login Button */}
                  {this.props.agent.power >= 12 && (
                    <>
                      {this.props.templates[this.props.templateIndex]
                        .partnerId ? (
                        <>
                          <ButtonGroup
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "7px" }}
                            ref={this.anchorRef}
                            aria-label="Button group with a nested menu"
                          >
                            <Button onClick={this.handleHoverOpen}>
                              Login
                            </Button>
                            <Button
                              size="small"
                              aria-controls={
                                open ? "split-button-menu" : undefined
                              }
                              aria-expanded={open ? "true" : undefined}
                              aria-label="select action"
                              aria-haspopup="menu"
                              onClick={this.handleHoverOpen}
                            >
                              <ArrowDropDown onClick={this.handleHoverOpen} />
                            </Button>
                          </ButtonGroup>
                          <Popper
                            sx={{ zIndex: 1 }}
                            open={open}
                            anchorEl={this.anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            placement="bottom-start"
                            // onMouseEnter={this.handleHoverOpen}
                            // onMouseLeave={this.handleHoverClose}
                            ref={this.setPopperNode}
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom"
                                      ? "center top"
                                      : "center bottom",
                                }}
                              >
                                <Paper onMouseLeave={this.handleClose}>
                                  <MenuList
                                    id="split-button-menu"
                                    autoFocusItem
                                  >
                                    {options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        onClick={(event) =>
                                          this.handleMenuItemClick(event, index)
                                        }
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </>
                      ) : (
                        <>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => this.loginAsOwner("owner")}
                            style={{ marginRight: "10px" }}
                          >
                            Login
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px" }}
                            onClick={() => this.loginAsOwner("copy")}
                          >
                            Copy Login URL
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  {(TENANT_ID && this.props.agent.power >= 10) ||
                  this.props.agent.power >= 12 ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleDrawerOpen}
                      >
                        View Logs
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
            </Grid>
            <div>
              <Drawer
                anchor="right"
                open={this.state.isDrawerOpen}
                onClose={this.handleDrawerClose}
                className={classes.drawerWidth}
              >
                <Box style={{ padding: "16px" }}>
                  <Typography
                    variant="h5"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "50px",
                      alignItems: "center",
                      marginBottom: "20px",
                      background: "#EBF5F3",
                    }}
                  >
                    Admin Logs
                  </Typography>
                  {this.state.logs.length === 0
                    ? "No Logs found"
                    : this.state.logs.map((log, index) => {
                        const createdAtDate = new Date(log.createdAt);
                        const formattedDate = `${createdAtDate.toLocaleDateString()}`;
                        const formattedTime = `${createdAtDate.toLocaleTimeString()}`;
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-end",
                              borderBottom: "1px solid #ccc",
                              paddingBottom: "10px",
                              marginBottom: "15px",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  marginBottom: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                <ReactJson
                                  src={log.body}
                                  displayDataTypes={false}
                                  displayObjectSize={false}
                                  enableClipboard={false}
                                  name="Changes"
                                />
                              </div>
                              <Typography variant="body1">
                                Name: {log.displayName}
                              </Typography>
                              <Typography variant="body1">
                                Date: {formattedDate}
                              </Typography>
                              <Typography variant="body1">
                                Time: {formattedTime}
                              </Typography>
                            </div>
                            {log?.body?.fields?.templateCredit && (
                              <Typography
                                variant="body1"
                                style={{
                                  width: "43%",
                                  color: "#0a474c",
                                  fontWeight: "bold",
                                }}
                              >
                                Template Credit: {`${template.currency} `}
                                {log.body.fields.templateCredit /
                                  Math.pow(10, 5)}
                              </Typography>
                            )}
                          </div>
                        );
                      })}
                </Box>
              </Drawer>
            </div>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                paddingLeft: "5%",
                paddingRight: "3%",
                width: "100%",
                marginLeft: "13%",
              }}
            >
              {tabValue === 1 && (
                <Box py={2} px={2} my={2} className={classes.outerContainer}>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Facebook Business Manager Information
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontSize: "14px" }}
                    >
                      <>Name - {this.state.FBMInfo?.name || ""}</>
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontSize: "14px" }}
                    >
                      <>Status - {this.state.FBMInfo?.status || ""}</>
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontSize: "14px" }}
                    >
                      <>Credit Line - {this.state.creditLineInfo || ""}</>
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update Messaging Tier Limit
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Messaging Tier Limit - {template.dailyTemplateLimit}
                    </Typography>
                    <Grid container>
                      <Grid item xs={5} alignItems="center">
                        <Autocomplete
                          disabled={this.props.agent.power <= 10}
                          value={this.state.messageLimit}
                          options={Tier_Limit.map((option) => option.name)}
                          onChange={(e, value, reason) =>
                            this.setState({
                              messageLimit: value,
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{
                                border: "1px solid ",
                                background: "white",
                                marginRight: 10,
                              }}
                              disabled={this.props.agent.power <= 10}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Select Limit"
                              name="messageLimit"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateMessagingLimit}
                            >
                              Update Message Limit
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          <Button
                            style={{ marginLeft: 10 }}
                            color="primary"
                            variant="contained"
                            onClick={this.syncMessagingLimit}
                          >
                            Sync
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      WABA Information
                    </Typography>
                    <Grid
                      container
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ fontWeight: "600", marginBottom: "10px" }}
                      >
                        <>
                          App id -{" "}
                          {this.props.templates[this.props.templateIndex]
                            ?.wabaAppId || ""}
                        </>
                      </Typography>
                      {this.state.wabaInfo?.map((waba, i) => (
                        <>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            className={classes.wabaInfo}
                          >
                            <Typography
                              variant="h5"
                              gutterBottom
                              style={{
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              {`Account ${i + 1}`}
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              <>Display name - {waba?.displayName || ""}</>
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              <>
                                Display name status -{" "}
                                {waba?.displayNameStatus || ""}
                              </>
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              Phone number - {waba?.phoneNumber || ""}
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              Waba number id - {waba?.id || ""}
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              Messaging Limit - {waba?.messagingLimit || ""}
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              <>Account status - {waba?.status || ""}</>
                            </Typography>

                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              <>
                                Quality rating -{" "}
                                {waba?.qualityRating
                                  ? `${
                                      ["Low", "FLAGGED", "RED"].includes(
                                        waba?.qualityRating
                                      )
                                        ? "Low"
                                        : [
                                            "UNFLAGGED",
                                            "Medium",
                                            "RESTRICTED",
                                            "YELLOW",
                                          ].includes(waba?.qualityRating)
                                        ? "Medium"
                                        : "High"
                                    }`
                                  : ""}
                              </>
                            </Typography>
                          </Grid>
                        </>
                      ))}{" "}
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      BVBP
                    </Typography>

                    <Grid container>
                      <Grid item xs={3} alignItems="center">
                        <TextField
                          placeholder="Enter BusinessID"
                          name="BusinessID"
                          onChange={(e) =>
                            this.setState({ businessId: e.target.value })
                          }
                          value={this.state.businessId}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          <Button
                            style={{ marginLeft: 10 }}
                            color="primary"
                            variant="contained"
                            disabled={!this.state.businessId}
                            onClick={this.getApplicationStatusHandler}
                          >
                            Check Status
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box>
                      <Grid container>
                        {this.state.bvbpCount != "" && (
                          <Grid
                            md={12}
                            xs={12}
                            style={{
                              marginTop: "5px",
                            }}
                          >
                            <Grid container md={12}>
                              <Typography variant="body1">
                                Bvbp apply count : {`${this.state.bvbpCount}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}

                        {this.state.verificationStatus && (
                          <Grid container md={12}>
                            <Typography variant="body1">
                              Bvbp apply status :{" "}
                              {this.state.verificationStatus}
                            </Typography>
                          </Grid>
                        )}
                        {this.state.bvbpApplicationResponse.length > 0 &&
                          this.state.bvbpApplicationResponse.map((data) => (
                            <Grid container xl={6} md={4} sm={6}>
                              <pre style={{ fontSize: "9px" }}>
                                {JSON.stringify(data, null, 2)}
                              </pre>
                            </Grid>
                          ))}

                        <Grid container style={{ marginTop: "5px" }}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={this.toggleBvbpDialog}
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <ApplyBvbp
                      toggleBvbpDialog={this.toggleBvbpDialog}
                      bvbpDialog={this.state.bvbpDialog}
                      onBvbpSave={this.onBvbpHandler}
                      defaultData={{}}
                    />
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update Provider Type
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Provider Type - {template.providerType || "V1"}
                    </Typography>
                    <Grid container>
                      <Grid item xs={5} alignItems="center">
                        <Autocomplete
                          disabled={this.props.agent.power <= 10}
                          value={this.state.providerType}
                          options={["v1", "v2", "v3"]}
                          onChange={(e, value, reason) =>
                            this.setState({
                              providerType: value,
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{
                                border: "1px solid ",
                                background: "white",
                                marginRight: 10,
                              }}
                              disabled={this.props.agent.power <= 10}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Select Provider Type"
                              name="providerType"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateProviderType}
                            >
                              Update Provider Type
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
              {tabValue === 3 && (
                <Box py={2} px={2} my={2} className={classes.outerContainer}>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update Messaging Tier Limit
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Messaging Tier Limit - {template.dailyTemplateLimit}
                    </Typography>
                    <Grid container>
                      <Grid item xs={5} alignItems="center">
                        <Autocomplete
                          disabled={this.props.agent.power <= 10}
                          value={this.state.messageLimit}
                          options={Tier_Limit.map((option) => option.name)}
                          onChange={(e, value, reason) =>
                            this.setState({
                              messageLimit: value,
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{
                                border: "1px solid ",
                                background: "white",
                                marginRight: 10,
                              }}
                              disabled={this.props.agent.power <= 10}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Select Limit"
                              name="messageLimit"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateMessagingLimit}
                            >
                              Update Message Limit
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          <Button
                            style={{ marginLeft: 10 }}
                            color="primary"
                            variant="contained"
                            onClick={this.syncMessagingLimit}
                          >
                            Sync
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Whatsapp Business Throughput Tier
                    </Typography>
                    <Box my={2}>
                      {this.props.agent.power <= 10 ? (
                        <></>
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item>
                            <Autocomplete
                              disabled={this.props.agent.power <= 10}
                              options={WABATPT_TIERS.map((option) => option)}
                              onChange={(e, value, reason) =>
                                this.handleAutoComplete(
                                  "wabaTpt",
                                  value,
                                  reason
                                )
                              }
                              value={template.wabaTpt}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className={classes.textField}
                                  InputProps={{ ...params.InputProps }}
                                  disabled={this.props.agent.power <= 10}
                                  placeholder="Select Throughput"
                                  style={{
                                    border: "1px solid ",
                                    background: "white",
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item>
                            {this.props.agent.power <= 10 ? (
                              <></>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={this.updateWabaTpt}
                              >
                                Update Throughput
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                    <Box my={2}>
                      {this.props.agent.power <= 10 ? (
                        <></>
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item>
                            <Autocomplete
                              disabled={this.props.agent.power <= 10}
                              options={WABATPT_TIERS.map((option) => option)}
                              onChange={(e, value, reason) =>
                                this.handleAutoComplete(
                                  "wabaStatusTpt",
                                  value,
                                  reason
                                )
                              }
                              value={template.wabaStatusTpt}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className={classes.textField}
                                  InputProps={{ ...params.InputProps }}
                                  disabled={this.props.agent.power <= 10}
                                  placeholder="Select Status Throughput"
                                  style={{
                                    border: "1px solid ",
                                    background: "white",
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item>
                            {this.props.agent.power <= 10 ? (
                              <></>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={this.updateWabaStatusTpt}
                              >
                                Update Status Throughput
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  </Box>

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      For GS only - WhatsApp Number - Enter number with dial
                      code
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Number in use - {template.whatsappNumber}
                    </Typography>
                    <Box my={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            style={{
                              width: "100%",
                              border: "1px solid ",
                              background: "white",
                              marginRight: 10,
                            }}
                            disabled={this.props.agent.power <= 10}
                            name="whatsappNumber"
                            onChange={this.handleNumberInput}
                            value={this.state.whatsappNumber}
                          />
                        </Grid>
                        <Grid item>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.updateWhatsAppNumber}
                            >
                              Update Number
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                  {/* <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Waba details
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <div>
                    <pre>wabaNumberId - {template.wabaNumberId}</pre>
                    <pre>wabaAppId - {template.wabaAppId}</pre>
                  </div>
                </Typography>
              </Box> */}

                  <Box py={2} px={2} my={2} className={classes.wabaBilling}>
                    <Box py={2} px={2} my={2}>
                      <Grid>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Reset Ongoing Billing Process
                        </Typography>
                        <div>
                          <Typography variant="body1" gutterBottom>
                            <pre>
                              Process Type:{" "}
                              {template.ongoingBillingProcess?.type}
                            </pre>
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <pre>
                              Plan Name:{" "}
                              {template.ongoingBillingProcess?.planName}
                            </pre>
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            {this.props.agent.power <= 10 ? (
                              <></>
                            ) : (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={this.openBillingDialog}
                              >
                                Set Null
                              </Button>
                            )}
                          </Typography>
                        </div>
                        <Dialog
                          open={this.state.billingProcessDialog}
                          onClose={this.closeBillingDialog}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Are you sure you want to reset the ongoing billing
                              process?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={this.closeBillingDialog}
                              color="primary"
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => {
                                this.setOngoingBilling();
                                this.closeBillingDialog();
                              }}
                              color="primary"
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    </Box>
                    <Box py={2} px={2} my={2}>
                      <Grid>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Reset waba deleted status
                        </Typography>
                        <Box>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              this.setTemplateInDb({
                                wabaDeleted: false,
                              })
                            }
                          >
                            Set Null
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                    <Box py={2} px={2} my={2}>
                      <Grid>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Reset waba disabled status
                        </Typography>
                        <Box>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              this.setTemplateInDb({
                                wabaAppStatus: null,
                              })
                            }
                          >
                            Set Null
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Migration
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {this.props.agent.power <= 10 ? (
                        <></>
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.updateMigrateStatus}
                        >
                          Migrate User
                        </Button>
                      )}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Suspend/Activate Project
                    </Typography>
                    <Grid container>
                      <Grid item xs={5} alignItems="center">
                        <Autocomplete
                          disabled={this.props.agent.power <= 10}
                          options={PROJECT_STATUS.map((option) => option)}
                          onChange={(e, value, reason) =>
                            this.handleAutoComplete(
                              "projectStatus",
                              value,
                              reason
                            )
                          }
                          value={template.status}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className={classes.textField}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Select Status"
                              style={{
                                border: "1px solid ",
                                background: "white",
                                marginRight: 10,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={this.updateProjectStatus}
                            >
                              Update Status
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Reset owner password
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Owner Email - {this.state.ownerName}
                    </Typography>
                    <Grid container>
                      <Grid item>
                        <TextField
                          placeholder="Enter Password"
                          name="password"
                          onChange={this.handleChange}
                          value={this.state.password}
                          style={{
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                          disabled={this.props.agent.power <= 10}
                        />
                      </Grid>
                      <Grid>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={this.resetPassword}
                            >
                              Reset Password
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update About
                    </Typography>
                    <Grid container>
                      <Grid item>
                        <TextField
                          placeholder="Enter About"
                          name="about"
                          onChange={this.handleChange}
                          value={this.state.about}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                          disabled={this.props.agent.power <= 10}
                        />
                      </Grid>
                      <Grid>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={this.resetAbout}
                              style={{ marginLeft: "10px" }}
                            >
                              Update About
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update Quality Rating
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Quality Rating -{" "}
                      {template.qualityRating ? template.qualityRating : "N/A"}
                    </Typography>
                    <Grid container>
                      <Grid item xs={5} alignItems="center">
                        <Autocomplete
                          disabled={this.props.agent.power <= 10}
                          value={this.state.qualityRating}
                          options={["Low", "Medium", "High"]}
                          onChange={(e, value, reason) =>
                            this.setState({
                              qualityRating: value,
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{
                                border: "1px solid ",
                                background: "white",
                                marginRight: 10,
                              }}
                              disabled={this.props.agent.power <= 10}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Select Rating"
                              name="qualityRating"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateQualtiyRating}
                            >
                              Update Quality Rating
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          <Button
                            style={{ marginLeft: 10 }}
                            color="primary"
                            variant="contained"
                            onClick={() => this.updateQualtiyRating("sync")}
                          >
                            Sync
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update Agents Limit
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Agents Limit -{" "}
                      {template.maxAgents ? template.maxAgents : "5"}
                    </Typography>
                    <Grid container>
                      <Grid item xs={3} alignItems="center">
                        <TextField
                          type="number"
                          placeholder="Enter Agent Limit"
                          name="agentLimit"
                          disabled={this.props.agent.power <= 10}
                          onChange={this.handleChange}
                          value={this.state.agentLimit}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateAgentLimit}
                            >
                              Update Agents
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update Attribute Limit
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Attribute Limit -{" "}
                      {template.attributeLimit ? template.attributeLimit : "20"}
                    </Typography>
                    <Grid container>
                      <Grid item xs={3} alignItems="center">
                        <TextField
                          type="number"
                          placeholder="Enter Attribute Limit"
                          name="attributeLimit"
                          disabled={this.props.agent.power <= 10}
                          onChange={this.handleChange}
                          value={this.state.attributeLimit}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateAttributeLimit}
                            >
                              Update Attribute Limit
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update Tag Limit
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Tag Limit -{" "}
                      {template.tagLimit ? template.tagLimit : "100"}
                    </Typography>
                    <Grid container>
                      <Grid item xs={3} alignItems="center">
                        <TextField
                          type="number"
                          placeholder="Enter Tag Limit"
                          name="tagLimit"
                          disabled={this.props.agent.power <= 10}
                          onChange={this.handleChange}
                          value={this.state.tagLimit}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateTagLimit}
                            >
                              Update Tag Limit
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update Schedule Campaign Limit
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Current Limit -{" "}
                      {template.scheduleCampaignLimit
                        ? template.scheduleCampaignLimit
                        : 100}
                    </Typography>
                    <Grid container>
                      <Grid item xs={3} alignItems="center">
                        <TextField
                          type="number"
                          placeholder="Enter Limit"
                          name="scheduleCampaignLimit"
                          disabled={this.props.agent.power <= 10}
                          onChange={this.handleChange}
                          value={this.state.scheduleCampaignLimit}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateScheduledCampaignLimit}
                            >
                              Update Limit
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography variant="body1" gutterBottom>
                      <strong> FB Business ID - </strong>
                      {template.fbBusinessId ? template.fbBusinessId : "null"} -
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      FB manager Status
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      FB manager Status - {template.fbManagerVerified || "N/A"}
                    </Typography>
                    <Grid container>
                      <Grid item xs={5} alignItems="center">
                        <Autocomplete
                          disabled={this.props.agent.power <= 10}
                          value={this.state.fbManager}
                          options={["VERIFIED", "UNVERIFIED"]}
                          onChange={(e, value, reason) =>
                            this.setState({
                              fbManager: value,
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{
                                border: "1px solid ",
                                background: "white",
                                marginRight: 10,
                              }}
                              disabled={this.props.agent.power <= 10}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Select Status"
                              name="fbManager"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updatefbManager}
                            >
                              Update fbManager status
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Display Name Status
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Display Name Status -{" "}
                      {template.displayNameVerified || "N/A"}
                    </Typography>
                    <Grid container>
                      <Grid item xs={5} alignItems="center">
                        <Autocomplete
                          disabled={this.props.agent.power <= 10}
                          value={this.state.displayNameVerified}
                          options={[
                            "ready",
                            "verified",
                            "APPROVED",
                            "DEFERRED",
                            "UNVERIFIED",
                            "NONE",
                          ]}
                          onChange={(e, value, reason) =>
                            this.setState({
                              displayNameVerified: value,
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              disabled={this.props.agent.power <= 10}
                              style={{
                                border: "1px solid ",
                                background: "white",
                                marginRight: 10,
                              }}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Select display name status"
                              name="displayNameVerified"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateDisplayName}
                            >
                              Update Display Name status
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Assistant Rate Limit
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {`Assistant Rate Limit - ${template.apiLimit || 100}/sec`}
                    </Typography>
                    <Grid container>
                      <Grid item xs={5} alignItems="center">
                        <Autocomplete
                          disableClearable
                          disabled={this.props.agent.power <= 10}
                          value={this.state.assistantApiLimit}
                          options={validLimits}
                          getOptionLabel={(option) => option.toString()}
                          onChange={(e, value, reason) =>
                            this.setState({
                              assistantApiLimit: value,
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              disabled={this.props.agent.power <= 10}
                              style={{
                                border: "1px solid ",
                                background: "white",
                                marginRight: 10,
                              }}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Select assistant rate limit"
                              name="assistantApiLimit"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateAssistantRateLimit}
                            >
                              Update Assistant Rate Limit
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Whatsapp Pay Update
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item md={4} alignItems="center">
                        <Typography variant="h5" paragraph noWrap={false}>
                          Select Pay Type
                        </Typography>
                        <Autocomplete
                          disabled={this.props.agent.power <= 10}
                          value={WHATSAPP_PAY_TYPES.find(
                            (lk) =>
                              lk.value ===
                              template?.orderCheckoutDetails?.whatsappPayType
                          )}
                          options={WHATSAPP_PAY_TYPES}
                          getOptionLabel={(op) => op.name}
                          onChange={(e, value, reason) =>
                            this.updateWhatsappPayDetails(
                              "whatsappPayType",
                              value.value
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{
                                border: "1px solid ",
                                background: "white",
                                marginRight: 10,
                              }}
                              disabled={this.props.agent.power <= 10}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Select Whatsapp Pay Type"
                              name="whatsapp_pay_type"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          Verify Whatsapp Pay Gateway
                        </Typography>
                        <Switch
                          disabled={this.props.agent.power <= 10}
                          checked={
                            template?.orderCheckoutDetails?.paymentOptions
                              ?.native_whatsapp_gateway === "approved"
                          }
                          onChange={(e, c) => {
                            const val = c ? "approved" : "rejected";
                            this.updateWhatsappPayDetails(
                              "paymentOptions.native_whatsapp_gateway",
                              val
                            );
                          }}
                          name="native_whatsapp_pay_gateway_approver"
                        />
                      </Grid>
                      {template?.partnerReferralId && (
                        <Grid item md={3}>
                          <Typography variant="h5" paragraph noWrap={false}>
                            Payment Template Enabled By Meta
                          </Typography>
                          <Autocomplete
                            disabled={this.props.agent.power <= 10}
                            value={TEMPLATE_ENABLE_BY_META_TYPES.find(
                              (lk) =>
                                lk.value ===
                                template?.orderCheckoutDetails
                                  ?.paymentTemplateEnabled
                            )}
                            options={TEMPLATE_ENABLE_BY_META_TYPES}
                            getOptionLabel={(op) => op.name}
                            onChange={(e, value, reason) =>
                              this.updateWhatsappPayDetails(
                                "paymentTemplateEnabled",
                                value.value
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{
                                  border: "1px solid ",
                                  background: "white",
                                  marginRight: 10,
                                }}
                                disabled={this.props.agent.power <= 10}
                                InputProps={{ ...params.InputProps }}
                                placeholder="Select Template Enbale Step"
                                name="payment_template_enabled"
                              />
                            )}
                          />
                        </Grid>
                      )}
                      <Grid item md={5}>
                        <Button
                          variant="contained"
                          style={{ marginRight: "2em" }}
                          color="primary"
                          onClick={() => {
                            if (this.state.editWhatsappPaySlabs) {
                              const vl = this.verifyJSON(
                                this.state.whatsappPaySlabs
                              );
                              if (vl)
                                this.setState({
                                  editWhatsappPaySlabs: false,
                                });
                            } else {
                              this.setState({
                                editWhatsappPaySlabs: true,
                              });
                            }
                          }}
                        >
                          {this.state.editWhatsappPaySlabs ? "Verify" : "Edit"}
                        </Button>
                        <Button
                          disabled={this.props.agent.power <= 10}
                          onClick={this.updatePaymentSlabs}
                          variant="contained"
                          color="primary"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Min Allowed Balance
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Current Allowance - {`${template.currency} `}
                      {template.minAllowedBalance
                        ? template.minAllowedBalance / 100000
                        : 0}
                    </Typography>
                    <Box my={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            style={{
                              width: "100%",
                              border: "1px solid ",
                              background: "white",
                              marginRight: 10,
                            }}
                            disabled={this.props.agent.power <= 10}
                            name="minAllowedBalance"
                            type="number"
                            onChange={this.handleInput}
                            value={this.state.minAllowedBalance}
                          />
                        </Grid>
                        <Grid item>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.updateMinAllowedBalance}
                            >
                              Update Min Allowed Balance
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              )}
              {tabValue === 6 && (
                <Box py={2} px={2} my={2} className={classes.outerContainer}>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Direct API -{" "}
                      {template?.directApikey ? "Enabled" : "Disabled"}
                    </Typography>
                    {this.props.agent.power <= 10 ? (
                      <></>
                    ) : (
                      <Switch
                        checked={!!template?.directApikey}
                        onChange={
                          template?.directApikey
                            ? this.disableDirectApi
                            : this.enableDirectApi
                        }
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    )}
                    <Typography variant="body2" gutterBottom>
                      To enable direct APIs for Project for testing purpose,
                      click on "Toggle" button above.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      To generate Refresh token and Access Token, fill the
                      fields below and click on "Generate Refresh and Access
                      Token" button.
                    </Typography>

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          Username
                        </Typography>
                        <TextField
                          name="username"
                          value={this.state?.directApiCredentials?.username}
                          onChange={(e) =>
                            this.setState({
                              directApiCredentials: {
                                ...this.state.directApiCredentials,
                                username: e.target.value,
                              },
                            })
                          }
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                          }}
                          disabled={this.props.agent.power <= 10}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          Password
                        </Typography>
                        <TextField
                          name="password"
                          type="password"
                          disabled={this.props.agent.power <= 10}
                          value={this.state?.directApiCredentials?.passoword}
                          onChange={(e) =>
                            this.setState({
                              directApiCredentials: {
                                ...this.state.directApiCredentials,
                                password: e.target.value,
                              },
                            })
                          }
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Box mt={4}>
                            <Button
                              onClick={() => this.enableDirectApi("token")}
                              variant="contained"
                              color="primary"
                            >
                              Generate Refresh & Access Token
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    </Grid>

                    {this.state.directApiCredentials?.refreshToken && (
                      <Grid item xs={12}>
                        <pre
                          style={{
                            background: "rgb(240, 240, 240)",
                            padding: 16,
                            borderRadius: 8,
                            marginTop: 16,
                            "overflow-x": "scroll",
                          }}
                        >
                          {JSON.stringify(
                            {
                              refreshToken:
                                this.state.directApiCredentials.refreshToken,
                              accessToken:
                                this.state.directApiCredentials.accessToken,
                            },
                            null,
                            2
                          )}
                        </pre>
                      </Grid>
                    )}
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update Direct API Webhook URL
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Please note that after updating the webhook URL, PLATFORM
                      will not save any information related to this assistant,
                      i.e: Messages, Templates
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{
                        fontFamily: "monospace",
                      }}
                    >
                      Events: Message Status, Template Status, etc.
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6}>
                        <TextField
                          type="text"
                          placeholder="Enter Webhook URL"
                          disabled={this.props.agent.power <= 10}
                          name="directApiWebookUrl"
                          onChange={this.handleChange}
                          value={this.state.directApiWebookUrl}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={this.updateWebhook}
                            >
                              Update Webhook
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    py={2}
                    px={2}
                    my={2}
                    className={classes.container}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Update click tracking
                        </Typography>
                        {this.props.agent.power > 10 && (
                          <Switch
                            checked={template?.clickTrackingEnabled}
                            onChange={this.enableClickTracking}
                            name="checkedA"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                    {template?.partnerId &&
                      template?.partnerId !== "627ea4876340e14cf7081778" && (
                        <Box py={2} px={2} my={2} className={classes.itemBox}>
                          <Box className={classes.itemContent}>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontWeight: "bold" }}
                            >
                              Update webhook for partner project
                            </Typography>
                            {this.props.agent.power <= 10 ? (
                              <></>
                            ) : (
                              <Switch
                                checked={this.state?.webhookAddon}
                                onChange={this.updateWebhookAddon}
                                name="webhooks"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      )}
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Update download report
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={template?.campaignReportingAllowed}
                            onChange={this.campaignReportingAllowed}
                            name="checkedA"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Update Campaign analytics
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={this.state.webhookAddon}
                            onChange={this.enableCampaignAnalytics}
                            name="campaignAnalytics"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Update Campaign scheduling
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={template?.campaignSchedulingEnabled}
                            onChange={this.enableCampaignScheduling}
                            name="campaignScheduling"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Ecom update
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={template?.isEcom}
                            onChange={this.enableEcom}
                            name="isEcom"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Enable Meta Ads Advance Targetting
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={template?.metaAdvanceTargetingEnabled}
                            onChange={this.enableMetaAdvanceTargeting}
                            name="metaAdvanceTargetingEnabled"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Update Filter Freeze
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={template?.filterFreezeEnabled}
                            onChange={this.enableFilterFreeze}
                            name="filterFreeze"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Update Chat Searchable
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={template?.chatSearchEnabled}
                            onChange={this.enableChatSearchable}
                            name="chatSearchable"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                        {/* {this.state.isChatScriptingRunning && (
                      <CircularProgress
                        size={20}
                        style={{ marginRight: "10px" }}
                      />
                    )} */}
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Update CSV Schedule Campaign
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            name="csvScheduleEnabled"
                            checked={template?.csvScheduleEnabled}
                            onChange={this.enableCSVScheduleEnabled}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Update Opt-in/out download report
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            name="optReportingEnabled"
                            checked={template?.optReportingEnabled}
                            onChange={this.optReporting}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Enable Birthday Campaign
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={this.state.birthdayCampaignEnabled}
                            onChange={this.enableBirthdayCampaign}
                            name="filterFreeze"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Enable Connect Flows
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={this.state.connectFlowsEnabled}
                            onChange={this.enableConnectFlows}
                            name="filterFreeze"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Skip user balance check before sending campaign
                        </Typography>
                        <Box
                          my={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Switch
                              name="skipBalanceCheck"
                              checked={template?.skipBalanceCheck}
                              onChange={this.skipBalanceCheck}
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box py={2} px={2} my={2} className={classes.itemBox}>
                      <Box className={classes.itemContent}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Show Pre-verified numbers in ES
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={
                              template?.virtualNumberConfig?.showPreVerified
                            }
                            onChange={this.togglePreVerifiedAccess}
                            name="showPreVerified"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold", marginBottom: "8px" }}
                    >
                      Add Special Access
                    </Typography>
                    <Grid item xs={5} alignItems="center">
                      <Autocomplete
                        disabled={this.props.agent.power <= 10}
                        value={this.state.selectedType}
                        options={["String", "Object", "Boolean"]}
                        onChange={(event, newValue) =>
                          this.setState({ selectedType: newValue })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{
                              border: "1px solid ",
                              background: "white",
                              marginRight: 10,
                              width: "365px",
                              marginBottom: "8px",
                            }}
                            disabled={this.props.agent.power <= 10}
                            InputProps={{ ...params.InputProps }}
                            placeholder="Select value type"
                            name="valueType"
                          />
                        )}
                      />
                    </Grid>
                    <TextField
                      style={{
                        width: "30%",
                        border: "1px solid ",
                        background: "white",
                        marginRight: "8px",
                      }}
                      disabled={this.props.agent.power <= 10}
                      name="paymentRemarks"
                      onChange={this.handleSpecialAcessKey}
                      placeholder="Enter key"
                      value={this.state.specialAccessKey}
                      required
                    />
                    <TextField
                      style={{
                        width: "30%",
                        border: "1px solid ",
                        background: "white",
                        marginRight: "8px",
                      }}
                      disabled={this.props.agent.power <= 10}
                      name="paymentRemarks"
                      onChange={this.handleSpecialAcessValue}
                      placeholder="Enter value"
                      value={this.state.specialAccessValue}
                      required
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        !this.state.specialAccessKey ||
                        !this.state.specialAccessValue
                      }
                      onClick={this.addSpecialAccess}
                    >
                      Add
                    </Button>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Box>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Existing Special Access
                      </Typography>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Button
                            color="primary"
                            onClick={() =>
                              this.setState({
                                showSpecialAccess:
                                  !this.state.showSpecialAccess,
                              })
                            }
                          >
                            {this.state.showSpecialAccess ? "Hide" : "Show"}{" "}
                          </Button>
                        </Grid>
                      </Grid>
                      <Collapse in={this.state.showSpecialAccess}>
                        <pre contentEditable={true} className={classes.codeBox}>
                          {this.state.existingSpecialAccess
                            ? JSON.stringify(
                                this.state.existingSpecialAccess,
                                null,
                                2
                              )
                            : ""}
                        </pre>
                      </Collapse>
                    </Box>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Box py={2} px={2} my={2} className={classes.container}>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontWeight: "bold" }}
                            >
                              View Webhook Logs
                            </Typography>
                            <Box
                              my={2}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={this.openDialog}
                              >
                                View Logs
                              </Button>
                            </Box>

                            <Drawer
                              anchor="right"
                              open={this.state.openWebhookDialog}
                              onClose={this.closeDialog}
                              className={classes.webhookdrawerWidth}
                            >
                              <Box
                                style={{
                                  padding: "16px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  height: "50px",
                                  alignItems: "center",
                                  marginBottom: "20px",
                                  background: "#EBF5F3",
                                  position: "sticky",
                                  top: 0,
                                  zIndex: 1,
                                }}
                              >
                                <Typography variant="h4">
                                  Webhook Logs
                                </Typography>{" "}
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.loadWebhookLogs}
                                  style={{ alignItems: "center" }}
                                  size="small"
                                  startIcon={<SyncIcon />}
                                >
                                  Refresh
                                </Button>
                              </Box>
                              <Box style={{ padding: "16px" }}>
                                {this.state.webhookLogs.length === 0
                                  ? "No Logs found"
                                  : this.state.webhookLogs.map((log, index) => {
                                      return (
                                        <div
                                          key={index}
                                          style={{
                                            borderBottom: "1px solid #ccc",
                                            marginBottom: "15px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginBottom: "10px",
                                              fontSize: "12px",
                                            }}
                                          >
                                            <ReactJson
                                              src={log}
                                              displayDataTypes={false}
                                              displayObjectSize={false}
                                              enableClipboard={true}
                                              collapsed={true}
                                              name={`Log ${index + 1}`}
                                            />
                                            <Typography variant="body1">
                                              Status:{" "}
                                              {log.statusCode
                                                ? log.statusCode
                                                : log.succededAt
                                                ? "200"
                                                : "Failed"}
                                            </Typography>
                                            <Typography variant="body1">
                                              Date:{" "}
                                              {new Date(
                                                log.eventCreatedAt
                                                  ? log.eventCreatedAt
                                                  : log.createdAt
                                                  ? log.createdAt
                                                  : null
                                              ).toLocaleString()}
                                            </Typography>
                                          </div>
                                        </div>
                                      );
                                    })}
                              </Box>
                            </Drawer>
                          </>
                        )}
                      </Box>
                    </Grid>
                    {(template.ecomAppStore === "shopify" ||
                      template.ecomAppStore === "woocommerce") && (
                      <>
                        <Grid item xs={3}>
                          <Box
                            py={2}
                            px={2}
                            my={2}
                            className={classes.container}
                          >
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              E-Commerce App Store
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                  this.handleEcomDrawerOpen(template)
                                }
                              >
                                {template.ecomAppStore === "shopify"
                                  ? "Shopify Logs"
                                  : "Woo Commerce Logs"}
                              </Button>
                            </Typography>
                          </Box>
                        </Grid>
                        <div>
                          <Drawer
                            anchor="right"
                            open={isEcomDrawerOpen}
                            onClose={this.handleEcomDrawerClose}
                            className={classes.ecomDrawerWidth}
                          >
                            <Box style={{ padding: "16px" }}>
                              <Typography
                                variant="h5"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "50px",
                                  alignItems: "center",
                                  marginBottom: "20px",
                                  background: "#EBF5F3",
                                }}
                              >
                                {template.ecomAppStore === "shopify"
                                  ? "Shopify Logs"
                                  : "Woo Commerce Logs"}
                              </Typography>
                              {template.ecomAppStore === "woocommerce" ? (
                                <>
                                  <Tabs
                                    value={selectedWooCommerceTab}
                                    onChange={this.handleWooCommerceTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <Tab label="Orders" />
                                    <Tab label="Abandoned Orders" />
                                  </Tabs>
                                  {selectedWooCommerceTab === 0 &&
                                    (wooCommerceLogs.WooComOrderEvents
                                      .length === 0
                                      ? "No Logs found"
                                      : wooCommerceLogs.WooComOrderEvents.map(
                                          (log, index) => {
                                            const createdAtDate = new Date(
                                              log.dateCreated
                                            );
                                            const formattedDate = `${createdAtDate.toLocaleDateString()}`;
                                            const formattedTime = `${createdAtDate.toLocaleTimeString()}`;
                                            return (
                                              <div
                                                key={log._id}
                                                style={{
                                                  borderBottom:
                                                    "1px solid #ccc",
                                                  marginBottom: "15px",
                                                }}
                                              >
                                                <Box
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Grid container spacing={2}>
                                                    <Grid item xs={4}>
                                                      <Typography variant="body1">
                                                        {formattedDate}
                                                      </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                      <Typography variant="body1">
                                                        {formattedTime}
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                  <IconButton
                                                    onClick={() =>
                                                      this.handleToggleCollapse(
                                                        index,
                                                        "expandedWooCommerceLogIndex"
                                                      )
                                                    }
                                                  >
                                                    <ExpandMore />
                                                  </IconButton>
                                                </Box>
                                                <Collapse
                                                  in={
                                                    expandedWooCommerceLogIndex ===
                                                    index
                                                  }
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  <div
                                                    style={{
                                                      marginBottom: "10px",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    <ReactJson
                                                      src={log}
                                                      collapsed={1}
                                                      displayDataTypes={false}
                                                      displayObjectSize={false}
                                                      enableClipboard={false}
                                                      name="Payload"
                                                    />
                                                  </div>
                                                </Collapse>
                                              </div>
                                            );
                                          }
                                        ))}
                                  {selectedWooCommerceTab === 1 &&
                                    (wooCommerceLogs.WooComAbandonedEvents
                                      .length === 0
                                      ? "No Logs found"
                                      : wooCommerceLogs.WooComAbandonedEvents.map(
                                          (log, index) => {
                                            const createdAtDate = new Date(
                                              log.dateCreated
                                            );
                                            const formattedDate = `${createdAtDate.toLocaleDateString()}`;
                                            const formattedTime = `${createdAtDate.toLocaleTimeString()}`;
                                            return (
                                              <div
                                                key={log._id}
                                                style={{
                                                  borderBottom:
                                                    "1px solid #ccc",
                                                  marginBottom: "15px",
                                                }}
                                              >
                                                <Box
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                      <Typography variant="body1">
                                                        {formattedDate}
                                                      </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <Typography variant="body1">
                                                        {formattedTime}
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                  <IconButton
                                                    onClick={() =>
                                                      this.handleToggleCollapse(
                                                        index,
                                                        "expandedWooCommerceLogIndex"
                                                      )
                                                    }
                                                  >
                                                    <ExpandMore />
                                                  </IconButton>
                                                </Box>
                                                <Collapse
                                                  in={
                                                    expandedWooCommerceLogIndex ===
                                                    index
                                                  }
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  <div
                                                    style={{
                                                      marginBottom: "10px",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    <ReactJson
                                                      src={log}
                                                      collapsed={1}
                                                      displayDataTypes={false}
                                                      displayObjectSize={false}
                                                      enableClipboard={false}
                                                      name="Payload"
                                                    />
                                                  </div>
                                                </Collapse>
                                              </div>
                                            );
                                          }
                                        ))}
                                </>
                              ) : shopifyLogs.length === 0 ? (
                                "No Logs found"
                              ) : (
                                shopifyLogs.map((log, index) => {
                                  const createdAtDate = new Date(log.createdAt);
                                  const formattedDate = `${createdAtDate.toLocaleDateString()}`;
                                  const formattedTime = `${createdAtDate.toLocaleTimeString()}`;
                                  return (
                                    <div
                                      key={log._id}
                                      style={{
                                        borderBottom: "1px solid #ccc",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Grid container spacing={2}>
                                          <Grid item xs={4}>
                                            <Typography variant="body1">
                                              {log.jobType}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <Typography variant="body1">
                                              {formattedDate}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <Typography variant="body1">
                                              {formattedTime}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        <IconButton
                                          onClick={() =>
                                            this.handleToggleCollapse(
                                              index,
                                              "expandedLogIndex"
                                            )
                                          }
                                        >
                                          <ExpandMore />
                                        </IconButton>
                                      </Box>
                                      <Collapse
                                        in={expandedLogIndex === index}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <div
                                          style={{
                                            marginBottom: "10px",
                                            fontSize: "12px",
                                          }}
                                        >
                                          <ReactJson
                                            src={log.payload}
                                            collapsed={1}
                                            displayDataTypes={false}
                                            displayObjectSize={false}
                                            enableClipboard={false}
                                            name="Payload"
                                          />
                                        </div>
                                      </Collapse>
                                    </div>
                                  );
                                })
                              )}
                            </Box>
                          </Drawer>
                        </div>
                      </>
                    )}
                  </Grid>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Private App
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleDialogOpen}
                      >
                        Create Private App
                      </Button>
                    </Typography>
                    <Dialog
                      open={this.state.isDialogOpen}
                      onClose={this.handleDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to create a private app for this
                          assistant?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleDialogClose}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            this.handleDialogClose();
                            this.createPrivateApp();
                          }}
                          color="primary"
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      <ul>
                        <li>
                          Ensure there is only one private app per project Id.
                        </li>
                        <li>
                          Click Update Webhook to create a new webhook if none
                          exists. If a webhook already exists, it will be
                          updated.
                        </li>
                        <li>
                          Before clicking Update Webhook, ensure you've entered
                          the webhook URL and selected the desired webhook
                          topics.
                        </li>
                      </ul>
                    </Typography>
                    {this.state.isLoading ? (
                      <CircularProgress />
                    ) : (
                      <div>
                        {this.state.privateApps.map((privateApp, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor: "white",
                              padding: "16px",
                              marginBottom: "16px",
                              borderRadius: "8px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{ marginBottom: "20px" }}
                            >
                              Private App Id: {privateApp.privateAppId}
                            </Typography>

                            <Typography variant="body2" gutterBottom>
                              ** API Key shown here will be lost after page
                              refresh **
                            </Typography>
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={6}>
                                <TextField
                                  disabled={true}
                                  type="text"
                                  placeholder="Click Regenerate to get a new key"
                                  fullWidth
                                  value={privateApp.apiPassword}
                                  InputProps={{
                                    endAdornment: (
                                      <Tooltip title="Click to copy API Key">
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            this.copyToClipboard(
                                              privateApp.apiPassword
                                            )
                                          }
                                        >
                                          <FileCopyIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <div>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.handleClickOpen(index)}
                                  >
                                    Regenerate
                                  </Button>
                                  <Dialog
                                    open={this.state.open}
                                    onClose={this.handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                  >
                                    <DialogContent>
                                      <DialogContentText id="alert-dialog-description">
                                        Doing this will Regenerate the API key.
                                        Are you sure you want to continue?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        onClick={this.handleClose}
                                        color="primary"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          this.reGeneratePassword(
                                            this.state.currentIndex
                                          );
                                          this.handleClose();
                                        }}
                                        color="primary"
                                      >
                                        Yes
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                              </Grid>
                            </Grid>
                            {privateApp.webhooks.map(
                              (webhook, webhookIndex) => (
                                <div
                                  key={webhookIndex}
                                  style={{
                                    backgroundColor: "#f0f0f0",
                                    padding: "16px",
                                    marginBottom: "10px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  {webhook.sharedSecret && (
                                    <Typography
                                      variant="body1"
                                      style={{ marginBottom: "20px" }}
                                    >
                                      Shared Secret: {webhook.sharedSecret}
                                    </Typography>
                                  )}
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Grid item xs={6}>
                                      <TextField
                                        placeholder="Enter Webhook URL"
                                        type="text"
                                        fullWidth
                                        value={webhook.webhookUrl}
                                        onChange={(event) =>
                                          this.handleUrlChange(
                                            event,
                                            index,
                                            webhookIndex
                                          )
                                        }
                                        style={{
                                          border: "1px solid ",
                                          backgroundColor: "white",
                                        }}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!webhook.webhookUrl}
                                        onClick={() =>
                                          this.createWebhook(
                                            index,
                                            webhookIndex
                                          )
                                        }
                                      >
                                        Update Webhook
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {Webhook_Topics.map((topic) => (
                                      <FormControlLabel
                                        key={topic.id}
                                        control={
                                          <Checkbox
                                            checked={webhook.topicsArray.includes(
                                              topic.label
                                            )}
                                            onChange={() =>
                                              this.handleCheckboxChange(
                                                index,
                                                webhookIndex,
                                                topic.label
                                              )
                                            }
                                            color="primary"
                                          />
                                        }
                                        label={topic.label}
                                        style={{
                                          width: "30%",
                                          marginBottom: "5px",
                                        }}
                                      />
                                    ))}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </Box>
                  {this.props.agent.power <= 11 ? (
                    <></>
                  ) : (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Assistant full details
                      </Typography>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Button
                            color="primary"
                            onClick={() =>
                              this.setState({
                                showCode: !this.state.showCode,
                              })
                            }
                          >
                            {this.state.showCode ? "Hide" : "Show"} Code
                          </Button>
                        </Grid>
                      </Grid>
                      <Collapse in={this.state.showCode}>
                        <pre contentEditable={true} className={classes.codeBox}>
                          {JSON.stringify(template, null, 2)}
                        </pre>
                      </Collapse>
                    </Box>
                  )}
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Cached Assistant
                    </Typography>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          color="primary"
                          onClick={() =>
                            this.setState({
                              showCachedAssCode: !this.state.showCachedAssCode,
                            })
                          }
                        >
                          {this.state.showCachedAssCode ? "Hide" : "Show"} Code
                        </Button>
                      </Grid>
                    </Grid>
                    <Collapse in={this.state.showCachedAssCode}>
                      <pre contentEditable={true} className={classes.codeBox}>
                        {this.state.cachedAssistant
                          ? JSON.stringify(this.state.cachedAssistant, null, 2)
                          : ""}
                      </pre>
                    </Collapse>
                  </Box>
                  {this.props.templates[this.props.templateIndex].partnerId ? (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Cached Partner
                      </Typography>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Button
                            color="primary"
                            onClick={() =>
                              this.setState({
                                showCachedPartnerCode:
                                  !this.state.showCachedPartnerCode,
                              })
                            }
                          >
                            {this.state.showCachedPartnerCode ? "Hide" : "Show"}{" "}
                            Code
                          </Button>
                        </Grid>
                      </Grid>
                      <Collapse in={this.state.showCachedPartnerCode}>
                        <pre contentEditable={true} className={classes.codeBox}>
                          {this.state.cachedPartner
                            ? JSON.stringify(this.state.cachedPartner, null, 2)
                            : ""}
                        </pre>
                      </Collapse>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              )}
              {tabValue === 4 && (
                <Box py={2} px={2} my={2} className={classes.outerContainer}>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <PlanFamily
                      agent={this.props.agent}
                      assistant={template}
                      showSuccessMessage={(alertMsg) =>
                        this.setState({
                          alert: true,
                          alertMsg,
                          alertSeverity: "success",
                        })
                      }
                      showErrorMessage={(alertMsg) =>
                        this.setState({
                          alert: true,
                          alertMsg,
                          alertSeverity: "error",
                        })
                      }
                    />
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Box py={2} px={2} my={2} className={classes.container}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Change Project Currency
                        </Typography>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ marginBottom: "10px" }}
                        >
                          Currency : {currency}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                this.openCurrencyDialog();
                              }}
                            >
                              change to {currency === "USD" ? "INR" : "USD"}
                            </Button>
                          )}
                        </Typography>

                        {/* currency change dialog */}

                        <div>
                          <Dialog
                            open={this.state.currencyDialog}
                            onClose={this.closeCurrencyDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            PaperProps={{
                              style: { height: "21%", width: "35%" },
                            }}
                          >
                            <DialogContent>
                              <DialogContentText>
                                Are you sure you want to change the currency to{" "}
                                <b>{currency === "USD" ? "INR" : "USD"}</b> for
                                this project?
                              </DialogContentText>
                              Template credits will be changed to{" "}
                              <b>
                                {" "}
                                {currency === "USD"
                                  ? `₹${
                                      (template.templateCredit * 80) / 100000
                                    }`
                                  : `$${template.templateCredit / 80 / 100000}`}
                              </b>{" "}
                              from{" "}
                              <b>
                                {currency === "USD"
                                  ? `$${template.templateCredit / 100000}`
                                  : `₹${template.templateCredit / 100000}`}
                              </b>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={this.closeCurrencyDialog}
                                color="primary"
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={() => {
                                  this.updateProjectCurrency();
                                  this.closeCurrencyDialog();
                                }}
                                color="primary"
                              >
                                Confirm
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box py={2} px={2} my={2} className={classes.container}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Active Plan
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          {displayActivePlan}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box py={2} px={2} my={2} className={classes.container}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Plan Duration
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          {template.planDuration}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {tabValue === 0 && (
                <Box py={2} px={2} my={2} className={classes.outerContainer}>
                  <Box
                    py={2}
                    px={2}
                    my={2}
                    className={classes.container}
                    style={{ display: "flex" }}
                  >
                    <Typography variant="h5">
                      <b>Project ID :</b> {templates[templateIndex]._id}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(
                          templates[templateIndex]._id
                        );
                        this.setState({
                          alert: true,
                          alertMsg: "Project ID copied successfully",
                          alertSeverity: "success",
                        });
                      }}
                      size="small"
                      style={{
                        marginLeft: 8,
                        backgroundColor: "#d6d6d6",
                      }}
                    >
                      <FileCopyIcon
                        style={{
                          fontSize: "15px",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box px={2} className={classes.container}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          WhatsApp Verification Status
                        </Typography>
                        <Box my={2}>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Switch
                              checked={template.isWhatsappVerified}
                              onChange={this.updateVerificationStatus}
                              name="checkedA"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    {template?.coexistence?.activatedOn && (
                      <Grid item xs={4}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{
                            marginLeft: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Coexistence :
                        </Typography>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{
                            marginLeft: "16px",
                            fontWeight: "bold",
                            color: "green",
                          }}
                        >
                          True
                        </Typography>
                      </Grid>
                    )}
                    {template?.wabaProgressStep === 9 &&
                      !this.state.wabaInfoLoading &&
                      !this.state.fbmInfoLoading && (
                        <>
                          {(!this.state.wabaInfo?.length ||
                            (template?.wabaAppStatus?.waba_ban_state !==
                              "DISABLE" &&
                              template?.wabaDeleted === false)) && (
                            <>
                              {(!this.state.wabaInfo?.length ||
                                template?.wabaAppStatus?.waba_ban_state ===
                                  "DISABLE") && (
                                <Grid item xs={4}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    style={{
                                      marginLeft: "16px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    WABA App Status:
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    style={{
                                      marginLeft: "16px",
                                      fontWeight: "bold",
                                      color: "red",
                                    }}
                                  >
                                    Disabled
                                  </Typography>
                                </Grid>
                              )}
                              {(!this.state.FBMInfo?.name ||
                                template?.wabaDeleted === true) && (
                                <Grid item xs={4}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    style={{
                                      marginLeft: "16px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    WABA Deleted:
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    style={{
                                      marginLeft: "16px",
                                      fontWeight: "bold",
                                      color: "red",
                                    }}
                                  >
                                    True
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          )}
                        </>
                      )}
                  </Grid>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Send test message to check API verification
                    </Typography>
                    {/* <Typography variant="body1" gutterBottom>
                  Tag Limit - {template.tagLimit}
                </Typography> */}
                    <Grid container>
                      <Grid item xs={3} alignItems="center">
                        <TextField
                          type="number"
                          disabled={this.props.agent.power <= 10}
                          placeholder="Enter Tag Limit"
                          name="testNumber"
                          onChange={this.handleChange}
                          value={this.state.testNumber}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.testAPINumber}
                            >
                              Test API Verification
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update WABA Number ID
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <pre>wabaNumberId - {template.wabaNumberId}</pre>
                    </Typography>
                    <Grid container>
                      <Grid item xs={3} alignItems="center">
                        <TextField
                          placeholder="Enter wabaNumberId"
                          name="wabaNumberId"
                          disabled={this.props.agent.power <= 10}
                          onChange={this.handleChange}
                          value={this.state.wabaNumberId}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateWabaNumberId}
                            >
                              Update wabaNumberId
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update WABA App ID
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <pre>wabaAppId - {template.wabaAppId}</pre>
                    </Typography>
                    <Grid container>
                      <Grid item xs={3} alignItems="center">
                        <TextField
                          placeholder="Enter wabaAppId"
                          name="wabaAppId"
                          disabled={this.props.agent.power <= 10}
                          onChange={this.handleChange}
                          value={this.state.wabaAppId}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateWabaAppId}
                            >
                              Update wabaAppId
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Update WABA progress step
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Current Step -{" "}
                      {template.wabaProgressStep
                        ? template.wabaProgressStep
                        : null}
                    </Typography>
                    <Grid container>
                      <Grid item xs={3} alignItems="center">
                        <TextField
                          type="number"
                          placeholder="Enter step"
                          name="wabaProgressStep"
                          disabled={this.props.agent.power <= 10}
                          onChange={this.handleChange}
                          value={this.state.wabaProgressStep}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              style={{ marginLeft: 10 }}
                              color="primary"
                              variant="contained"
                              onClick={this.updateWabaStep}
                            >
                              Update WABA Step
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      form to re-register
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <pre>WABA PIN - {template.wabaPin}</pre>
                    </Typography>
                    <Grid container>
                      <Grid item>
                        <TextField
                          placeholder="enter pin"
                          name="pin"
                          disabled={this.props.agent.power <= 10}
                          onChange={this.handleChange}
                          value={this.state.pin}
                          style={{
                            padding: "8px 20px",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 3,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <>
                              <Button
                                style={{ marginRight: 3, marginLeft: 3 }}
                                color="primary"
                                variant="contained"
                                onClick={this.registerWithPin}
                              >
                                Register
                              </Button>

                              {this.state.errorCurl?.event === "register" && (
                                <Button
                                  style={{ marginRight: 3, marginLeft: 3 }}
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      this.state.errorCurl?.curl
                                    );
                                    this.setState({
                                      alert: true,
                                      alertMsg: "cURL copied successfully",
                                      alertSeverity: "success",
                                    });
                                  }}
                                >
                                  Copy cURL
                                </Button>
                              )}
                            </>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} alignItems="center">
                        <Autocomplete
                          disableClearable
                          value={this.state.mode}
                          options={["SMS", "VOICE"]}
                          onChange={(e, value, reason) =>
                            this.setState({
                              mode: value,
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{
                                padding: "8px 20px",
                                border: "1px solid ",
                                background: "white",
                                marginRight: 3,
                                marginLeft: 5,
                              }}
                              disabled={this.props.agent.power <= 10}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Verification Mode"
                              name="Verification Mode"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Tooltip
                              title="Whatsapp is not verified"
                              disableHoverListener={template.isWhatsappVerified}
                            >
                              <span>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={this.triggerOtp}
                                  style={{ marginLeft: 10 }}
                                  disabled={!template.isWhatsappVerified}
                                >
                                  Trigger OTP
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      form to verify
                    </Typography>
                    <Grid container>
                      <Grid item>
                        <TextField
                          placeholder="enter code"
                          name="verifyCode"
                          onChange={this.handleChange}
                          value={this.state.verifyCode}
                          style={{
                            padding: "8px 20px",
                            border: "1px solid ",
                            background: "white",
                            marginRight: 3,
                          }}
                          disabled={this.props.agent.power <= 10}
                        />
                      </Grid>
                      <Grid item>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Typography variant="body1" gutterBottom>
                            <Tooltip
                              title="WhatsApp is not verified"
                              disableHoverListener={template.isWhatsappVerified}
                            >
                              <span>
                                <Button
                                  style={{ marginRight: 3, marginLeft: 3 }}
                                  color="primary"
                                  variant="contained"
                                  onClick={this.verifyCode}
                                  disabled={!template.isWhatsappVerified}
                                >
                                  Verify
                                </Button>
                              </span>
                            </Tooltip>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      WABA Information
                    </Typography>
                    <Grid
                      container
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ fontWeight: "600", marginBottom: "10px" }}
                      >
                        <>
                          App id -{" "}
                          {this.props.templates[this.props.templateIndex]
                            ?.wabaAppId || ""}
                        </>
                      </Typography>
                      {this.state.wabaInfo?.map((waba, i) => (
                        <>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            className={classes.wabaInfo}
                          >
                            <Typography
                              variant="h5"
                              gutterBottom
                              style={{
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              {`Account ${i + 1}`}
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              <>Display name - {waba?.displayName || ""}</>
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              <>
                                Display name status -{" "}
                                {waba?.displayNameStatus || ""}
                              </>
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              Phone number - {waba?.phoneNumber || ""}
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              Waba number id - {waba?.id || ""}
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              Messaging Limit - {waba?.messagingLimit || ""}
                            </Typography>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              <>Account status - {waba?.status || ""}</>
                            </Typography>

                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              <>
                                Quality rating -{" "}
                                {waba?.qualityRating
                                  ? `${
                                      ["Low", "FLAGGED", "RED"].includes(
                                        waba?.qualityRating
                                      )
                                        ? "Low"
                                        : [
                                            "UNFLAGGED",
                                            "Medium",
                                            "RESTRICTED",
                                            "YELLOW",
                                          ].includes(waba?.qualityRating)
                                        ? "Medium"
                                        : "High"
                                    }`
                                  : ""}
                              </>
                            </Typography>
                          </Grid>
                        </>
                      ))}{" "}
                    </Grid>
                  </Box>
                </Box>
              )}
              {tabValue === 7 && (
                <Box py={2} px={2} my={2} className={classes.outerContainer}>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Remaining Credit
                          <ul>
                            <li>
                              For v3, Keep the excessMac reading as New Plan
                              will reset it
                            </li>
                            <li>
                              Default currency in INR for Dollar Billing contact
                              developers
                            </li>
                            <li>Max Agents 5 for v3 pricing</li>
                            <li>35p template msg pricing for v3 pricing</li>
                            <li>
                              v3 monthly pricing & renewal can be done from here
                            </li>
                            <li>
                              v3 yearly plan can be added but can't be renewed
                              from here
                            </li>
                            <li>To upgrade monthly to yearly - Contact devs</li>
                          </ul>
                        </Typography>
                        <Typography variant="h5" paragraph noWrap={false}>
                          {template.remainingCredit / 100000}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ fontWeight: "bold" }}
                          >
                            Add Credit
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            name="remainingCredit"
                            value={this.state.remainingCredit}
                            onChange={this.handleInput}
                            type="number"
                            disabled={this.props.agent.power <= 10}
                            style={{
                              width: "100%",
                              border: "1px solid ",
                              background: "white",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ fontWeight: "bold" }}
                          >
                            Change Plan
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Autocomplete
                            disabled={this.props.agent.power <= 10}
                            options={PLANS.map((option) => option)}
                            onChange={(e, value, reason) =>
                              this.handleAutoComplete(
                                "activePlan",
                                value,
                                reason
                              )
                            }
                            value={template.activePlan}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className={classes.textField}
                                InputProps={{ ...params.InputProps }}
                                disabled={this.props.agent.power <= 10}
                                placeholder="Select plan"
                                style={{
                                  border: "1px solid ",
                                  background: "white",
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Grid item xs={12}>
                            <Button
                              onClick={this.updateCredit}
                              variant="contained"
                              color="primary"
                              disabled={template.partnerId}
                            >
                              Update
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      WhatsApp App Name - Enter name (default to{" "}
                      {isTenant ? tenantDetails.name : "AiSensy"})
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Number in use - {template.appName}
                    </Typography>
                    <Box my={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            disabled={this.props.agent.power <= 10}
                            style={{
                              width: "100%",
                              border: "1px solid ",
                              background: "white",
                              marginRight: 10,
                            }}
                            name="appName"
                            onChange={this.handleNumberInput}
                            value={this.state.appName}
                          />
                        </Grid>
                        <Grid item>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.updateAppName}
                            >
                              Update App Name
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      360 API Key (Do this at the end)
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      API Key in use - {template.apiKey360}
                    </Typography>
                    <Box my={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            disabled={this.props.agent.power <= 10}
                            style={{
                              width: "100%",
                              border: "1px solid ",
                              background: "white",
                              marginRight: 10,
                            }}
                            name="apiKey360"
                            onChange={this.handleNumberInput}
                            value={this.state.apiKey360}
                          />
                        </Grid>
                        <Grid item>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.updateApiKey}
                            >
                              Update Key
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Sync Template Msg ( for migration from GS to 360D only)
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {this.props.agent.power <= 10 ? (
                        <></>
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.syncTemplateMsg}
                        >
                          Sync Template Messages
                        </Button>
                      )}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Migrate Template Msg ( for migration of new clients only)
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {this.props.agent.power <= 10 ? (
                        <></>
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.migrateTemplateMsg}
                        >
                          Migrate Template Messages
                        </Button>
                      )}
                    </Typography>
                  </Box>

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Migrate Phone Number
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Mention the number to be migrated to this project
                    </Typography>
                    <Box
                      my={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={5}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          Phone Number - eg: 919419112345
                        </Typography>
                        <TextField
                          name="migrationNumber"
                          value={this.state.migrationNumber}
                          onChange={this.handleChange}
                          type="number"
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                          }}
                          disabled={this.props.agent.power <= 10}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          Source Project ID (Optional)
                        </Typography>
                        <TextField
                          name="srcAssistantId"
                          disabled={this.props.agent.power <= 10}
                          value={this.state.srcAssistantId}
                          onChange={this.handleChange}
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                          }}
                        />
                      </Grid>
                    </Box>
                    <Grid item xs={12}>
                      {this.props.agent.power <= 10 ? (
                        <></>
                      ) : (
                        <Button
                          onClick={this.migratePhoneNumber}
                          variant="contained"
                          color="primary"
                        >
                          Update
                        </Button>
                      )}
                    </Grid>
                  </Box>
                  {!template?.partnerId &&
                    template?.partnerId !== "627ea4876340e14cf7081778" && (
                      <Box py={2} px={2} my={2} className={classes.container}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Migrate Project to Partner
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          Transfer this project from{" "}
                          {isTenant ? tenantDetails.name : "AiSensy"} to the
                          Partner
                        </Typography>
                        <Box
                          my={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid item xs={4}>
                            <Typography variant="h5" paragraph noWrap={false}>
                              Enter the ID of the Partner
                            </Typography>
                            <TextField
                              name="partnerId"
                              value={this.state.partnerId}
                              onChange={this.handleChange}
                              style={{
                                width: "80%",
                                border: "1px solid ",
                                background: "white",
                              }}
                              disabled={this.props.agent.power <= 10}
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant="h5" paragraph noWrap={false}>
                              Plan(Required for Whitelabel Partner)
                            </Typography>
                            <Autocomplete
                              disabled={this.props.agent.power <= 10}
                              options={PARTNER_PLANS.map((option) => option)}
                              onChange={(e, value, reason) =>
                                this.handleAutoComplete(
                                  "defaultPlan",
                                  value,
                                  reason
                                )
                              }
                              value={this.state.defaultPlan}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className={classes.textField}
                                  InputProps={{ ...params.InputProps }}
                                  placeholder="Select Plan"
                                  style={{
                                    border: "1px solid ",
                                    background: "white",
                                    marginRight: 10,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="h5" paragraph noWrap={false}>
                              Activation Date
                            </Typography>
                            <TextField
                              name="activationDate"
                              defaultValue={(
                                this.state.activationDate || moment()
                              ).format("YYYY-MM-DD")}
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              style={{
                                width: "80%",
                                border: "1px solid ",
                                background: "white",
                              }}
                              onChange={(e) =>
                                this.setState({
                                  activationDate: moment(e.target.value),
                                })
                              }
                            />
                          </Grid>
                        </Box>
                        <Grid item xs={12}>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              onClick={this.migrateProjectToPartner}
                              variant="contained"
                              color="primary"
                            >
                              Update
                            </Button>
                          )}
                        </Grid>
                      </Box>
                    )}
                  {template?.partnerId &&
                    template?.partnerId !== "627ea4876340e14cf7081778" && (
                      <Box py={2} px={2} my={2} className={classes.container}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Migrate Partner Project to{" "}
                          {isTenant ? tenantDetails.name : "AiSensy"}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          Transfer this project of the partner to{" "}
                          {isTenant ? tenantDetails.name : "AiSensy"}
                        </Typography>
                        <Grid item xs={12}>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              onClick={this.migratePartnerProjectToAisensy}
                              variant="contained"
                              color="primary"
                            >
                              Update
                            </Button>
                          )}
                        </Grid>
                      </Box>
                    )}
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                    >
                      Migrate Templates
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Migrate Templates Across Projects
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid>
                        <TextField
                          name="migrateTemplatesFrom"
                          placeholder="Source Project Id"
                          onChange={this.handleNumberInput}
                          disabled={this.props.agent.power <= 10}
                          value={this.state.migrateTemplatesFrom}
                          style={{
                            width: "15rem",
                            border: "1px solid ",
                            background: "white",
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Typography
                          gutterBottom
                          variant="body1"
                          style={{ fontWeight: "bold", margin: "0 1rem" }}
                        >
                          To
                        </Typography>
                      </Grid>
                      <Grid>
                        <TextField
                          name="migrateTemplatesTo"
                          onChange={this.handleNumberInput}
                          placeholder="Destination Project Id"
                          value={this.state.migrateTemplatesTo}
                          disabled={this.props.agent.power <= 10}
                          style={{
                            width: "15rem",
                            background: "white",
                            border: "1px solid ",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "1rem" }}>
                      {this.props.agent.power > 10 && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.migrateTemplates}
                        >
                          Migrate
                        </Button>
                      )}
                    </Grid>
                  </Box>

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                    >
                      Enable CTWA Affiliate Funnel
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Please specify the affiliateId & the adId on AiSensy
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid>
                        <TextField
                          name="affiliateId"
                          placeholder="Affiliate Id"
                          onChange={(e) =>
                            this.handleObjectKeyInput("ctwaFunnel", e)
                          }
                          disabled={this.props.agent.power <= 10}
                          value={this.state.ctwaFunnel?.affiliateId}
                          style={{
                            width: "15rem",
                            border: "1px solid ",
                            background: "white",
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Typography
                          gutterBottom
                          variant="body1"
                          style={{ fontWeight: "bold", margin: "0 1rem" }}
                        >
                          -
                        </Typography>
                      </Grid>
                      <Grid>
                        <TextField
                          name="adId"
                          onChange={(e) =>
                            this.handleObjectKeyInput("ctwaFunnel", e)
                          }
                          placeholder="Ad Id"
                          value={this.state.ctwaFunnel?.adId}
                          disabled={this.props.agent.power <= 10}
                          style={{
                            width: "15rem",
                            background: "white",
                            border: "1px solid ",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "1rem" }}>
                      {this.props.agent.power > 10 && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.enableCtwaFunnel}
                        >
                          Enable
                        </Button>
                      )}
                    </Grid>
                  </Box>

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                    >
                      Get Campaign Error cURL
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Please specify the campaignId & the userNumber
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid>
                        <TextField
                          name="campaignId"
                          placeholder="Campaign ID"
                          onChange={(e) =>
                            this.handleObjectKeyInput("campaignErrorCurl", e)
                          }
                          disabled={this.props.agent.power <= 10}
                          value={this.state.campaignErrorCurl?.campaignId}
                          style={{
                            width: "15rem",
                            border: "1px solid ",
                            background: "white",
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Typography
                          gutterBottom
                          variant="body1"
                          style={{ fontWeight: "bold", margin: "0 1rem" }}
                        >
                          -
                        </Typography>
                      </Grid>
                      <Grid>
                        <TextField
                          name="userNumber"
                          onChange={(e) =>
                            this.handleObjectKeyInput("campaignErrorCurl", e)
                          }
                          placeholder="User Number"
                          value={this.state.campaignErrorCurl?.userNumber}
                          disabled={this.props.agent.power <= 10}
                          style={{
                            width: "15rem",
                            background: "white",
                            border: "1px solid ",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "1rem" }}>
                      {this.props.agent.power > 10 && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.getCampaignErrorCurl}
                        >
                          Get cURL
                        </Button>
                      )}
                    </Grid>
                  </Box>
                </Box>
              )}
              {tabValue === 2 && (
                <Box py={2} px={2} my={2} className={classes.outerContainer}>
                  <Grid style={{ display: "flex", flexDirection: "row" }}>
                    {this.props.agent.power <= 9 ? (
                      <></>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.openChargebeeDialog}
                        style={{
                          marginRight: "10px",
                          marginLeft: "15px",
                          marginTop: "20px",
                        }}
                      >
                        View Chargebee Logs
                      </Button>
                    )}
                    <Dialog
                      open={this.state.chargeBeeDialog}
                      onClose={this.closeChargebeeDialog}
                      maxWidth="lg"
                      PaperProps={{
                        style: { width: "75%", height: "100%" },
                      }}
                    >
                      <DialogContent>
                        <div
                          style={{
                            overflow: "auto",
                            maxHeight: "calc(100vh - 150px)",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              position: "sticky",
                              top: 0,
                              zIndex: 1,
                              background: "#fff",
                              height: "30px",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              padding: "10px",
                            }}
                          >
                            <strong>Previous Object</strong>
                            <strong>Modified Object</strong>
                          </div>
                          {this.state.chargeBeeLogs.length === 0 ? (
                            <div
                              style={{
                                textAlign: "center",
                                width: "100%",
                                marginTop: "20%",
                              }}
                            >
                              No ChargeBee Logs Found :(
                            </div>
                          ) : (
                            this.state.chargeBeeLogs.map((item, index) => (
                              <Grid
                                container
                                spacing={2}
                                key={index}
                                style={{
                                  display: "flex",
                                  padding: "10px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      color: "#1b6d92",
                                    }}
                                  >
                                    Date:{" "}
                                    {new Date(item.createdAt).toLocaleString()}
                                  </div>
                                  <div
                                    style={{
                                      borderBottom: "1px solid #ccc",
                                      flex: 1,
                                    }}
                                  >
                                    <pre>
                                      {JSON.stringify(
                                        item.previousObject,
                                        null,
                                        2
                                      )}
                                    </pre>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      borderBottom: "1px solid #ccc",
                                      flex: 1,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          color: "#1b6d92",
                                        }}
                                      >
                                        Date:{" "}
                                        {new Date(
                                          item.createdAt
                                        ).toLocaleString()}
                                      </div>
                                      {item.invoiceNo ? (
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            color: "#1b6d92",
                                          }}
                                        >
                                          Invoice No: {item.invoiceNo}
                                        </div>
                                      ) : null}
                                    </div>
                                    <pre>
                                      {JSON.stringify(
                                        item.modifiedObject,
                                        null,
                                        2
                                      )}
                                    </pre>
                                  </div>
                                </Grid>
                              </Grid>
                            ))
                          )}
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.closeChargebeeDialog}
                          color="primary"
                        >
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Grid item>
                      {/* POWER 12,11 IMPOSED FOR LOGIN */}
                      {this.props.agent.power <= 10 ? (
                        <></>
                      ) : (
                        <>
                          {!TENANT_ID ? (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginTop: "20px" }}
                              onClick={this.openChargebee}
                            >
                              Open Chargebee
                            </Button>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Template Credit (v2 & v3) - Enter amount in +/-
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Current Balance - {`${template.currency} `}
                      {template.templateCredit
                        ? template.templateCredit / 100000
                        : 0}
                    </Typography>
                    <Box my={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            style={{
                              width: "48%",
                              border: "1px solid ",
                              background: "white",
                              marginRight: 10,
                            }}
                            disabled={this.props.agent.power <= 10}
                            name="templateCredit"
                            type="number"
                            onChange={this.handleInput}
                            value={this.state.templateCredit}
                          />
                          <TextField
                            style={{
                              width: "48%",
                              border: "1px solid ",
                              background: "white",
                            }}
                            disabled={this.props.agent.power <= 10}
                            name="paymentRemarks"
                            onChange={this.handleNumberInput}
                            placeholder="Enter Remarks"
                            value={this.state.paymentRemarks}
                            required
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <NativeSelect
                            fullWidth
                            value={this.state.selectedTemplateCreditAction}
                            disabled={this.props.agent.power <= 10}
                            onChange={(e) =>
                              this.setState({
                                selectedTemplateCreditAction: e.target.value,
                              })
                            }
                            input={
                              <InputBase
                                classes={{
                                  input: classes.selectFieldInput,
                                }}
                              />
                            }
                          >
                            <option value="ADD">ADD</option>
                            <option value="SUBTRACT">SUBTRACT</option>
                          </NativeSelect>
                        </Grid>
                        <Grid item>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Tooltip
                              title={
                                !this.state.paymentRemarks
                                  ? "Please enter payment remarks"
                                  : ""
                              }
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    this.updateTemplateCredit();
                                    this.setState({
                                      paymentRemarks: undefined,
                                    });
                                  }}
                                  disabled={!this.state.paymentRemarks}
                                >
                                  Update Template Credit
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid
                      container
                      spacing={8}
                      style={{
                        padding: "20px 30px 25px 30px",
                        marginTop: "10px",
                      }}
                    >
                      <Grid xs={12}>
                        <Grid
                          xs={8}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Typography
                            variant="h4"
                            style={{ marginBottom: "15px", fontSize: "15px" }}
                          >
                            {"Download Template Credit Report"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        style={{
                          display:
                            window.innerWidth <= 850 ? "inline-block" : "flex",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <DateFilter
                          filterKey="createdAt"
                          filterName="Created At"
                          classes={classes}
                          applied={this.state.applied}
                          setDate={this.setDate}
                          setFocus={this.setFocus}
                          checkDateInput={this.checkDateInput}
                          clearDate={this.clearDate}
                        />
                        <Tooltip title="Clear filter">
                          <IconButton
                            onClick={this.clearDate}
                            size="small"
                            style={{
                              marginLeft: 8,
                              backgroundColor: "#d6d6d6",
                            }}
                          >
                            <Clear />
                          </IconButton>
                        </Tooltip>
                        <Button
                          color="primary"
                          variant="contained"
                          style={{
                            marginLeft: 8,
                            height: 30,
                          }}
                          onClick={() => {
                            this.getReport();
                          }}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Meta Ads Credit (v2 & v3) - Enter amount in +/-
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Current Balance - {`${template.currency} `}
                      {template.metaAdsCredit
                        ? template.metaAdsCredit / 100000
                        : 0}
                    </Typography>
                    <Box my={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            style={{
                              width: "48%",
                              border: "1px solid ",
                              background: "white",
                              marginRight: 10,
                            }}
                            disabled={this.props.agent.power <= 10}
                            name="metaAdsCredit"
                            type="number"
                            onChange={this.handleInput}
                            value={this.state.metaAdsCredit}
                          />
                          <TextField
                            style={{
                              width: "48%",
                              border: "1px solid ",
                              background: "white",
                            }}
                            disabled={this.props.agent.power <= 10}
                            name="metaCreditRemarks"
                            onChange={this.handleNumberInput}
                            placeholder="Enter Remarks"
                            value={this.state.metaCreditRemarks}
                            required
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <NativeSelect
                            fullWidth
                            value={this.state.selectedMetaCreditAction}
                            disabled={this.props.agent.power <= 10}
                            onChange={(e) =>
                              this.setState({
                                selectedMetaCreditAction: e.target.value,
                              })
                            }
                            input={
                              <InputBase
                                classes={{
                                  input: classes.selectFieldInput,
                                }}
                              />
                            }
                          >
                            <option value="ADD">ADD</option>
                            <option value="SUBTRACT">SUBTRACT</option>
                          </NativeSelect>
                        </Grid>
                        <Grid item>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Tooltip
                              title={
                                !this.state.metaCreditRemarks
                                  ? "Please enter payment remarks"
                                  : ""
                              }
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    this.updateMetaCredit();
                                    this.setState({
                                      metaCreditRemarks: undefined,
                                    });
                                  }}
                                  disabled={!this.state.metaCreditRemarks}
                                >
                                  Update Meta Credit
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Grid>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Reset Ongoing Billing Process
                      </Typography>
                      <div>
                        <Typography variant="body1" gutterBottom>
                          <pre>
                            Process Type: {template.ongoingBillingProcess?.type}
                          </pre>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <pre>
                            Plan Name:{" "}
                            {template.ongoingBillingProcess?.planName}
                          </pre>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={this.openBillingDialog}
                            >
                              Set Null
                            </Button>
                          )}
                        </Typography>
                      </div>
                      <Dialog
                        open={this.state.billingProcessDialog}
                        onClose={this.closeBillingDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you want to reset the ongoing billing
                            process?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={this.closeBillingDialog}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.setOngoingBilling();
                              this.closeBillingDialog();
                            }}
                            color="primary"
                          >
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Suspend/Activate Project
                    </Typography>
                    <Grid container>
                      <Grid item xs={5} alignItems="center">
                        <Autocomplete
                          disabled={this.props.agent.power <= 10}
                          options={PROJECT_STATUS.map((option) => option)}
                          onChange={(e, value, reason) =>
                            this.handleAutoComplete(
                              "projectStatus",
                              value,
                              reason
                            )
                          }
                          value={template.status}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className={classes.textField}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Select Status"
                              style={{
                                border: "1px solid ",
                                background: "white",
                                marginRight: 10,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={this.updateProjectStatus}
                            >
                              Update Status
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Change Project Currency
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ marginBottom: "10px" }}
                    >
                      Currency : {currency}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {this.props.agent.power <= 10 ? (
                        <></>
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            this.openCurrencyDialog();
                          }}
                        >
                          change to {currency === "USD" ? "INR" : "USD"}
                        </Button>
                      )}
                    </Typography>

                    {/* currency change dialog */}

                    <div>
                      <Dialog
                        open={this.state.currencyDialog}
                        onClose={this.closeCurrencyDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={{
                          style: { height: "21%", width: "35%" },
                        }}
                      >
                        <DialogContent>
                          <DialogContentText>
                            Are you sure you want to change the currency to{" "}
                            <b>{currency === "USD" ? "INR" : "USD"}</b> for this
                            project?
                          </DialogContentText>
                          Template credits will be changed to{" "}
                          <b>
                            {" "}
                            {currency === "USD"
                              ? `₹${(template.templateCredit * 80) / 100000}`
                              : `$${template.templateCredit / 80 / 100000}`}
                          </b>{" "}
                          from{" "}
                          <b>
                            {currency === "USD"
                              ? `$${template.templateCredit / 100000}`
                              : `₹${template.templateCredit / 100000}`}
                          </b>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={this.closeCurrencyDialog}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.updateProjectCurrency();
                              this.closeCurrencyDialog();
                            }}
                            color="primary"
                          >
                            Confirm
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </Box>
                </Box>
              )}
              {tabValue === 8 && (
                <Box py={2} px={2} my={2} className={classes.outerContainer}>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography variant="h5">
                      <b>Meta Ad Account ID :</b>{" "}
                      {templates[templateIndex].selectedMetaAdAccountId}
                    </Typography>
                    <Typography variant="h5">
                      <b>Meta User ID :</b>{" "}
                      {templates[templateIndex].selectedMetaUserId}
                    </Typography>
                    <Typography variant="h5">
                      <b>Meta Page ID :</b>{" "}
                      {templates[templateIndex].selectedMetaPageId}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Meta Ads Credit (v2 & v3) - Enter amount in +/-
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Current Balance - {`${template.currency} `}
                      {template.metaAdsCredit
                        ? template.metaAdsCredit / 100000
                        : 0}
                    </Typography>
                    <Box my={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            style={{
                              width: "48%",
                              border: "1px solid ",
                              background: "white",
                              marginRight: 10,
                            }}
                            disabled={this.props.agent.power <= 10}
                            name="metaAdsCredit"
                            type="number"
                            onChange={this.handleInput}
                            value={this.state.metaAdsCredit}
                          />
                          <TextField
                            style={{
                              width: "48%",
                              border: "1px solid ",
                              background: "white",
                            }}
                            disabled={this.props.agent.power <= 10}
                            name="metaCreditRemarks"
                            onChange={this.handleNumberInput}
                            placeholder="Enter Remarks"
                            value={this.state.metaCreditRemarks}
                            required
                          />
                        </Grid>
                        <Grid item>
                          {this.props.agent.power <= 10 ? (
                            <></>
                          ) : (
                            <Tooltip
                              title={
                                !this.state.metaCreditRemarks
                                  ? "Please enter payment remarks"
                                  : ""
                              }
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    this.updateMetaCredit();
                                    this.setState({
                                      metaCreditRemarks: undefined,
                                    });
                                  }}
                                  disabled={!this.state.metaCreditRemarks}
                                >
                                  Update Meta Credit
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  {this.props.agent.power > 10 &&
                    this.state.isAdsMangerSetupPresent &&
                    !this.state.fbConnectedAssistantId && (
                      <Box py={2} px={2} my={2} className={classes.container}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          Remove Ads Manager Setup
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          Remove Ads Manager Setup associated with this project
                        </Typography>

                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={this.checkCampaignStatus}
                              disabled={
                                this.state.fetchCampaignStatusLoader &&
                                !this.state.setCampaignStatusLoader
                              }
                            >
                              Remove Ads Manager Setup
                            </Button>

                            {this.state.fetchCampaignStatusLoader &&
                              !this.state.setCampaignStatusLoader && (
                                <CircularProgress
                                  size={24}
                                  style={{ marginLeft: 16 }}
                                />
                              )}
                            <Dialog
                              open={this.state.removeAdsSetupManagerDialog}
                              onClose={() =>
                                this.setState({
                                  removeAdsSetupManagerDialog: false,
                                })
                              }
                            >
                              {/* <DialogTitle>Confirm Delete</DialogTitle> */}
                              <DialogContent>
                                <DialogContentText>
                                  Are you sure you want to Remove Ads Manager
                                  Setup?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      removeAdsSetupManagerDialog: false,
                                    })
                                  }
                                  color="primary"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={this.removeAdsSetupManager}
                                  color="primary"
                                  autoFocus
                                  disabled={
                                    this.state.adsSetupDeletionInProgress
                                  }
                                >
                                  {this.state.adsSetupDeletionInProgress
                                    ? "Deleting..."
                                    : "Delete"}
                                </Button>
                              </DialogActions>
                            </Dialog>

                            <Dialog
                              open={this.state.pauseAllCampaignsDialog}
                              onClose={() =>
                                this.setState({
                                  pauseAllCampaignsDialog: false,
                                })
                              }
                            >
                              {/* <DialogTitle>Confirm Delete</DialogTitle> */}
                              <IconButton
                                onClick={() =>
                                  this.setState({
                                    pauseAllCampaignsDialog: false,
                                  })
                                }
                                style={{
                                  marginLeft: "auto",
                                }}
                              >
                                <Close />
                              </IconButton>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-around",
                                }}
                              >
                                <p>Status: {this.state.allCampaignStatus}</p>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {this.state.setCampaignStatusLoader && (
                                    <CircularProgress
                                      size={20}
                                      style={{ marginRight: "10px" }}
                                    />
                                  )}
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          this.state.allCampaignStatus ===
                                          "ACTIVE"
                                        }
                                        onChange={() =>
                                          this.setAllCampaignStatus()
                                        }
                                        color="primary"
                                        disabled={
                                          this.state.setCampaignStatusLoader
                                        }
                                      />
                                    }
                                    label={
                                      this.state.allCampaignStatus === "ACTIVE"
                                        ? "Active"
                                        : "Paused"
                                    }
                                  />
                                </div>
                              </div>
                              <p
                                style={{
                                  margin: 0,
                                  marginLeft: "40px",
                                  fontSize: "11px",
                                }}
                              >
                                Total Campaigns: {this.state.campaigns.length}
                              </p>
                              <DialogContent>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    maxHeight: "500px",
                                    width: "350px",
                                    overflow: "scroll",
                                  }}
                                >
                                  {this.state.campaigns.map((campaign) => (
                                    <div
                                      style={{
                                        padding: "5px",
                                        margin: "4px 0",
                                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                        borderRadius: "4px",
                                        backgroundColor: "#fff",
                                        border: "1px solid #e0e0e0",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <p style={{ margin: 0 }}>
                                          {" "}
                                          {campaign.name}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          {campaign.status}
                                        </p>
                                      </div>
                                      <p
                                        style={{ margin: 0, fontSize: "11px" }}
                                      >
                                        id: {campaign.id}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </DialogContent>
                            </Dialog>
                          </div>
                        </Grid>
                      </Box>
                    )}

                  {this.props.agent.power > 10 &&
                    this.state.fbConnectedAssistantId && (
                      <Box
                        py={2}
                        px={2}
                        my={2}
                        className={classes.container}
                        style={{ display: "flex" }}
                      >
                        <Typography
                          gutterBottom
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          Project Id with Ads Setup:{" "}
                          {this.state.fbConnectedAssistantId}
                        </Typography>

                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              this.state.fbConnectedAssistantId
                            );
                            this.setState({
                              alert: true,
                              alertMsg: "Project ID copied successfully",
                              alertSeverity: "success",
                            });
                          }}
                          size="small"
                          style={{
                            marginLeft: 8,
                            backgroundColor: "#d6d6d6",
                          }}
                        >
                          <FileCopyIcon
                            style={{
                              fontSize: "15px",
                            }}
                          />
                        </IconButton>
                      </Box>
                    )}
                </Box>
              )}
              {tabValue === 5 && (
                <Box py={2} px={2} my={2} className={classes.outerContainer}>
                  <Box
                    py={2}
                    px={2}
                    my={2}
                    className={classes.container}
                    style={{ display: "flex" }}
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Flow Update
                    </Typography>
                    <Box
                      my={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          Change Flow Status
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={template?.flowConfiguration?.isEnabled}
                            onChange={this.enableFlow}
                            name="flowEnabled"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          Enable Order Flow
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={template?.isOrderFlowEnabled}
                            onChange={this.enableFlowOrderFlow}
                            name="flowEnabled"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          No. of flow will be created
                        </Typography>
                        <TextField
                          name="flowCount"
                          value={this.state?.flowConfiguration?.flowCount ?? 0}
                          onChange={this.handleFlowConfigurationInput}
                          type="number"
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                          }}
                          disabled={this.props.agent.power <= 10}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          No. of active flow at a time
                        </Typography>
                        <TextField
                          name="activeFlowCount"
                          disabled={this.props.agent.power <= 10}
                          value={
                            this.state?.flowConfiguration?.activeFlowCount ?? 0
                          }
                          onChange={this.handleFlowConfigurationInput}
                          type="number"
                          style={{
                            width: "100%",
                            border: "1px solid ",
                            background: "white",
                          }}
                        />
                      </Grid>
                    </Box>
                    <Grid item xs={12}>
                      {this.props.agent.power <= 10 ? (
                        <></>
                      ) : (
                        <Button
                          onClick={this.updateFlowObject}
                          variant="contained"
                          color="primary"
                        >
                          Update
                        </Button>
                      )}
                    </Grid>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Flow Transfer
                    </Typography>
                    <TextField
                      style={{
                        width: "30%",
                        border: "1px solid ",
                        background: "white",
                        marginRight: "8px",
                      }}
                      disabled={
                        this.props.agent.power <= 10 ||
                        !template?.flowConfiguration?.isEnabled
                      }
                      name="flowId"
                      onChange={this.handleFlowTransferId}
                      placeholder="Enter FlowId"
                      value={this.state.transferFlowId}
                      required
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!this.state.transferFlowId}
                      onClick={() => this.handleFlowTransfer(false)}
                      style={{
                        marginRight: "8px",
                      }}
                    >
                      Copy flow
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!this.state.transferFlowId}
                      onClick={() => this.handleFlowTransfer(true)}
                    >
                      Transfer flow
                    </Button>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Box
                      my={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          Enable Delay
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={template?.flowConfiguration?.delayEnabled}
                            onChange={this.handleDelayEnabled}
                            name="delayEnabled"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          Trial claimed
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={
                              template?.flowConfiguration?.isTrialClaimed
                            }
                            onChange={this.handleIsTrialClaimed}
                            name="trialClaimed"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="h5" paragraph noWrap={false}>
                          Enable TimeOut
                        </Typography>
                        {this.props.agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Switch
                            checked={
                              template?.flowConfiguration?.timeoutEnabled
                            }
                            onChange={this.handleTimeoutEnabled}
                            name="enableTimeout"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <div className={classes.root}>
              <Grid container justify="center">
                <Grid item xs={12} md={8}>
                  <Box py={2} px={2} my={2}>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                    ></Grid>
                  </Box>

                  {/* <Box py={2} px={2} my={2} className={classes.container}>
                {this.props.agent.power <= 10 ? (
                  <></>
                ) : (
                  <>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Login as owner
                    </Typography>
                    <Box
                      my={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.loginAsOwner}
                      >
                        Login
                      </Button>
                    </Box>
                  </>
                )}
              </Box> */}
                  {/* <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Sync Channel Status
                </Typography>
                <Grid container>
                  <Grid item>
                    <Typography variant="body1" gutterBottom>
                      <Button
                        style={{ marginLeft: 10 }}
                        color="primary"
                        variant="contained"
                        onClick={this.syncChannelStatus}
                      >
                        Sync Channel Status
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Box> */}
                </Grid>
              </Grid>
            </div>

            <Snackbar
              open={this.state.alert}
              autoHideDuration={4000}
              onClose={this.closeSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={this.closeSnackbar}
                severity={this.state.alertSeverity}
              >
                {this.state.alertMsg}
              </Alert>
            </Snackbar>
          </Box>
        </div>
      </>
    );
  }
}

// function getFormattedDate(date) {
//   const d = new Date(date);
//   const options = { year: "numeric", month: "long", day: "numeric" };
//   return d.toLocaleDateString("en-US", options);
// }

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <div className={classes.datePickerPosition}>
      <Grid item xs style={{ display: "flex", justifyContent: "flex-start" }}>
        <DateRangePicker
          startDatePlaceholderText="From"
          endDatePlaceholderText="To"
          onClose={() => checkDateInput(filterKey)}
          startDateId="startDate"
          endDateId="endDate"
          startDate={A.startDate}
          endDate={A.endDate}
          onDatesChange={({ startDate, endDate }) =>
            setDate(filterKey, startDate, endDate)
          }
          onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
          navPosition="navPositionTop"
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          customArrowIcon={true}
          screenReaderInputMessage={" "}
          small
          readOnly
          isOutsideRange={() => false}
          maxDate={moment(new Date())}
          minDate={moment("2023-04-25T12:31:17.617+00:00")}
          minimumNights={0}
          focusedInput={A.focusedInput}
        />
      </Grid>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  codeBox: {
    backgroundColor: "#eee",
    padding: "1rem",
    borderRadius: "12px",
    overflow: "auto",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  indicator: {
    backgroundColor: "rgb(70 20 134)",
  },
  outerContainer: {
    background: "#EBF5F3",
    borderRadius: "15px",
    width: "80%",
  },
  container: {
    background: "#EBF5F3",
    borderRadius: "15px",
  },
  disabledContainer: {
    background: "#dcdcdc",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
  drawerWidth: {
    "& .MuiDrawer-paper": {
      width: "32%",
    },
  },
  ecomDrawerWidth: {
    "& .MuiDrawer-paper": {
      width: "40%",
    },
  },
  webhookdrawerWidth: {
    "& .MuiDrawer-paper": {
      width: "40%",
    },
  },
  wabaBilling: {
    background: "#EBF5F3",
    borderRadius: "15px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gridGap: 14,
  },
  wabaInfo: {
    border: "1px solid #b0b0b0",
    width: "100%",
    padding: "16px",
    borderRadius: "8px",
    background: "#e9e9e9",
    margin: "10px 0px",
  },
  itemBox: {
    flex: "1 1 calc(50% - 16px)",
    margin: "8px",
    boxSizing: "border-box",
  },
  itemContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  selectFieldInput: {
    borderRadius: "8px",
    backgroundColor: "white",
    border: "1px solid black",
    padding: "12px",
  },
});
const connectedAssistant = connect((state) => ({
  agent: state.login.user,
  tenantDetails: state.tenant.tenant,
}))(Assistant);
export default withStyles(styles)(connectedAssistant);
